/* eslint-disable camelcase */
import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { toast } from 'react-toastify'
import { uniqBy, remove, get } from 'lodash'

import CreateEditDomainList from './create-edit-domain-list'
import { overseer } from '../../utils/api'


const propTypes = {
  cuid: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  edit: PropTypes.bool,
  trigger: PropTypes.node.isRequired,
  loadDomainLists: PropTypes.func.isRequired,
  domainList: PropTypes.object,
}

const defaultProps = {
  edit: false,
  domainList: {
    DomainListID: -1,
    domains: [],
    DomainListName: '',
  },
}

class CreateEditDomainListContainer extends Component {
  state = {
    list_name: '',
    domains: [],
    modalOpen: false,
    loadingDomains: false,
    loadingCreate: false,
  }

  initDomainList() {
    const { domainList } = this.props

    this.setState({
      list_name: domainList.DomainListName,
      domains: domainList.domains,
    })
  }

  onInputChange = (field) => (_, { value }) => {
    this.setState({ [field]: value })
  }

  openModal = () => {
    this.initDomainList()

    this.setState({ modalOpen: true })
  }

  closeModal = () => this.setState({ modalOpen: false })

  createEditList = async () => {
    this.setState({ loadingCreate: true })
    const { cuid, edit, loadDomainLists, domainList } = this.props
    const list_id = domainList.DomainListID
    const { list_name, domains } = this.state

    const domain_ids = domains.map((d) => d.DomainID)

    const payload = {
      list_name,
      domain_ids,
    }

    try {
      if (edit) {
        await overseer({
          url: `/customers/${cuid}/targeting_lists/domain/${list_id}`,
          method: 'put',
          data: payload,
        })
        loadDomainLists(list_id)
      } else {
        await overseer({
          url: `/customers/${cuid}/targeting_lists/domain`,
          method: 'post',
          data: payload,
        })
        loadDomainLists()
      }
      this.closeModal()
    } catch (err) {
      if (get(err, 'response.data.message', false)) {
        toast.error(err.response.data.message)
      } else {
        toast.error(`Unable to ${edit ? 'Edit' : 'Create'} Domain List`)
      }
    } finally {
      this.setState({ loadingCreate: false })
    }
  }

  addDomain = (domain) => {
    const { domains } = this.state
    let newDomains = domains.slice()

    if (domain instanceof Array) {
      newDomains = [...newDomains, ...domain]
    } else {
      newDomains.unshift(domain)
    }

    newDomains = uniqBy(newDomains, 'DomainID')

    this.setState({ domains: newDomains })
  }

  removeDomain = (domain) => {
    const { domains } = this.state
    const newDomains = domains.slice()
    remove(newDomains, (d) => d.DomainID === domain.DomainID)

    this.setState({ domains: newDomains })
  }

  render() {
    const {
      modalOpen,
      loadingDomains,
      loadingCreate,
      list_name,
      domains,
    } = this.state

    const { trigger, edit, domainList } = this.props

    const newTrigger = React.cloneElement(trigger, { onClick: this.openModal })

    return (
      <CreateEditDomainList
        createEditList={this.createEditList}
        onInputChange={this.onInputChange}
        modalOpen={modalOpen}
        openModal={this.openModal}
        closeModal={this.closeModal}
        loadingDomains={loadingDomains}
        addDomain={this.addDomain}
        removeDomain={this.removeDomain}
        trigger={newTrigger}
        loadingCreate={loadingCreate}
        edit={edit}
        list_name={list_name}
        domains={domains}
        domainListID={domainList.DomainListID}
      />
    )
  }
}

CreateEditDomainListContainer.propTypes = propTypes

CreateEditDomainListContainer.defaultProps = defaultProps

export default CreateEditDomainListContainer
