import { combineReducers } from 'redux'


export function isLoading(
  state = {
    summary: true,
    app: true,
    banner: true,
    bannerPos: true,
    browser: true,
    iab: true,
    lang: true,
    network: true,
    os: true,
    sesDep: true,
    website: true,
    city: true,
    userSeg: true,
    poi: true,
    viewability: true,
    conversion: true,
    geocohort: true,
  },
  action,
) {
  switch (action.type) {
    case 'SUMMARY_IS_LOADING':
      return { ...state, summary: action.isLoading }
    case 'APP_IS_LOADING':
      return { ...state, app: action.isLoading }
    case 'BANNER_IS_LOADING':
      return { ...state, banner: action.isLoading }
    case 'BANNERPOS_IS_LOADING':
      return { ...state, bannerPos: action.isLoading }
    case 'BROWSER_IS_LOADING':
      return { ...state, browser: action.isLoading }
    case 'IAB_IS_LOADING':
      return { ...state, iab: action.isLoading }
    case 'LANG_IS_LOADING':
      return { ...state, lang: action.isLoading }
    case 'NETWORK_IS_LOADING':
      return { ...state, network: action.isLoading }
    case 'OS_IS_LOADING':
      return { ...state, os: action.isLoading }
    case 'SESDEP_IS_LOADING':
      return { ...state, sesDep: action.isLoading }
    case 'WEBSITE_IS_LOADING':
      return { ...state, website: action.isLoading }
    case 'CITY_IS_LOADING':
      return { ...state, city: action.isLoading }
    case 'SEGMENT_IS_LOADING':
      return { ...state, userSeg: action.isLoading }
    case 'POI_IS_LOADING':
      return { ...state, poi: action.isLoading }
    case 'VIEWABILITY_IS_LOADING':
      return { ...state, viewability: action.isLoading }
    case 'CONVERSION_IS_LOADING':
      return { ...state, conversion: action.isLoading }
    case 'GEOCOHORT_IS_LOADING':
      return { ...state, geocohort: action.isLoading }
    default:
      return state
  }
}

export function data(
  state = {
    summary: {},
    app: [],
    banner: [],
    bannerPos: [],
    browser: [],
    iab: [],
    lang: [],
    network: [],
    os: [],
    sesDep: [],
    website: [],
    city: [],
    userSeg: [],
    poi: [],
    viewability: [],
    conversion: [],
    geocohort: [],
  },
  { type, data },
) {
  switch (type) {
    case 'SUMMARY_RETRIEVED':
      return { ...state, summary: data }
    case 'APP_RETRIEVED':
      return { ...state, app: data }
    case 'BANNER_RETRIEVED':
      return { ...state, banner: data }
    case 'BANNERPOS_RETRIEVED':
      return { ...state, bannerPos: data }
    case 'BROWSER_RETRIEVED':
      return { ...state, browser: data }
    case 'IAB_RETRIEVED':
      return { ...state, iab: data }
    case 'LANG_RETRIEVED':
      return { ...state, lang: data }
    case 'NETWORK_RETRIEVED':
      return { ...state, network: data }
    case 'OS_RETRIEVED':
      return { ...state, os: data }
    case 'SESDEP_RETRIEVED':
      return { ...state, sesDep: data }
    case 'WEBSITE_RETRIEVED':
      return { ...state, website: data }
    case 'CITY_RETRIEVED':
      return { ...state, city: data }
    case 'SEGMENT_RETRIEVED':
      return { ...state, userSeg: data }
    case 'POI_RETRIEVED':
      return { ...state, poi: data }
    case 'VIEWABILITY_RETRIEVED':
      return { ...state, viewability: data }
    case 'CONVERSION_RETRIEVED':
      return { ...state, conversion: data }
    case 'GEOCOHORT_RETRIEVED':
      return { ...state, geocohort: data }
    default:
      return state
  }
}

export function dates(
  state = {
    insights: {
      startDate: null,
      endDate: null,
    },
    timeSeries: {
      startDate: null,
      endDate: null,
    },
  },
  { type, startDate, endDate },
) {
  switch (type) {
    case 'SET_INSIGHTS_DURATION':
      return { ...state, insights: { startDate, endDate } }
    case 'SET_TIMESERIES_DURATION':
      return { ...state, timeSeries: { startDate, endDate } }
    default:
      return state
  }
}

export function domainLists(state = { domain: [], domainBlack: [] }, { type, data }) {
  switch (type) {
    case 'DOMAINDATA_RETRIEVED':
      return data
    default:
      return state
  }
}

const reportReducer = combineReducers({
  data,
  dates,
  domainLists,
  isLoading,
})

export default reportReducer
