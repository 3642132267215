import React from 'react'
import PropTypes from 'prop-types'

import { Segment, Header, Input } from 'semantic-ui-react'

import ListLoader from '../../common-components/list-loader'
import DomainButtons from './domain-buttons'
import styles from './domain-picker.css'


const propTypes = {
  loadingDomains: PropTypes.bool.isRequired,
  searchedDomains: PropTypes.arrayOf(PropTypes.object).isRequired,
  addDomain: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  filterText: PropTypes.string.isRequired,
  searchText: PropTypes.string.isRequired,
  filteredDomains: PropTypes.arrayOf(PropTypes.object).isRequired,
  removeDomain: PropTypes.func.isRequired,
}

const DomainPicker = ({
  loadingDomains,
  searchedDomains,
  addDomain,
  onChange,
  filterText,
  searchText,
  filteredDomains,
  removeDomain,
}) => (
  <Segment.Group horizontal>
    <Segment loading={loadingDomains} className={styles.equalWidth}>
      <Header content='Search For Domains' textAlign='center' />
      <Input
        fluid
        disabled={loadingDomains}
        onChange={onChange('searchText')}
        icon='search'
        placeholder='Search Domains'
        iconPosition='left'
        value={searchText}
      />
      {searchedDomains.length > 0
        ? (
          <ListLoader
            Component={DomainButtons}
            icon='plus'
            onClick={addDomain}
            list={searchedDomains}
            listName='domains'
            pageSize={30}
            divClassName={styles.scrollingSegment}
            scrollingDiv
          />
        )
        : <div className={styles.emptyDiv} />}
    </Segment>
    <Segment className={styles.equalWidth}>
      <Header content='Domains in List' textAlign='center' />
      <Input
        fluid
        onChange={onChange('filterText')}
        icon='search'
        placeholder='Search...'
        iconPosition='left'
        value={filterText}
      />
      {filteredDomains.length > 0
        ? (
          <ListLoader
            Component={DomainButtons}
            icon='minus'
            onClick={removeDomain}
            list={filteredDomains}
            listName='domains'
            pageSize={30}
            divClassName={styles.scrollingSegment}
            scrollingDiv
          />
        )
        : <div className={styles.emptyDiv} />}
    </Segment>
  </Segment.Group>
)

DomainPicker.propTypes = propTypes

export default DomainPicker
