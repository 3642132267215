.label {
  cursor: pointer !important;
  font-weight: unset !important;
  font-size: unset !important;
  display: inline-flex !important;
  align-items: center !important;
}

:global(.semanticCalendar .CalendarDay--selected-start) {
  background-color: #1C70B0 !important;
  border-color: #1C70B0 !important;
}

:global(.semanticCalendar .CalendarDay--selected-end) {
  background-color: #1C70B0 !important;
  border-color: #1C70B0 !important;
}

:global(.semanticCalendar .CalendarDay--valid.CalendarDay--hovered-span.CalendarDay--hovered) {
  background-color: #1C70B0 !important;
  border-color: #1C70B0 !important;
}

:global(.semanticCalendar .CalendarDay--selected-span) {
  background-color: #2185d0 !important;
  border: 1px solid #2185d0 !important;
}

:global(.semanticCalendar .CalendarDay--hovered) {
  background-color: #1C70B0 !important;
  color: white !important;
}

:global(.semanticCalendar .CalendarDay--selected-span .CalendarDay--hovered) {
  background-color: #1C70B0 !important;
  border: 1px solid #1C70B0 !important;
  color: white !important;
}

:global(.semanticCalendar .CalendarDay--valid.CalendarDay--hovered-span) {
  background-color: #2185d0 !important;
  border-color: #2185d0 !important;
  color: white !important;
}

:global(.semanticCalendar .CalendarDay--after-hovered-start) {
  background-color: #2185d0 !important;
  border-color: #80e8e0 !important;
  color: white !important;
}

:global(.CalendarDay) {
  border: 0 !important;
}

:global(.semanticCalendar .CalendarMonth__caption) {
  margin-bottom: 1rem !important;
}
