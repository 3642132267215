import React from 'react'
import PropTypes from 'prop-types'

import { Header } from 'semantic-ui-react'


const propTypes = {
  errorCode: PropTypes.number.isRequired,
  errorMessage: PropTypes.string,
}

const errorMap = {
  403: 'Unfortunately, you do not have permission to view this page',
  404: 'Oops! We can\'t seem to find the page you are looking for',
}

const ErrorPage = ({ errorCode, errorMessage }) => (
  // eslint-disable-next-line max-len
  <Header as='h1' color='grey' style={{ fontSize: '4rem' }} textAlign='center'>
    {errorCode}
    <Header.Subheader>
      {errorMap[errorCode] || errorMessage || 'Unknown Error'}
    </Header.Subheader>
  </Header>
)

ErrorPage.propTypes = propTypes

export default ErrorPage
