import { hot } from 'react-hot-loader'
import React, { useEffect } from 'react'

import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { init, configureScope } from '@sentry/browser'

import { useAuthContext, InitStorageHost } from '@eqworks/common-login'

// import '../semantic-themes/themes.less'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import 'semantic-ui-css/semantic.min.css'
import './app.css'
import CommonLogin from '../login/common-login'
import LoggedInContainer from '../logged-in-container/logged-in-container'


// Sentry configs
init({ dsn: process.env.REACT_APP_SENTRY_URL || '' })
configureScope((scope) => {
  scope.setTags({
    REACT_APP_API_VER: process.env.REACT_APP_API_VER,
    REACT_APP_KEY_WARDEN_STAGE: process.env.REACT_APP_KEY_WARDEN_STAGE,
    COMMIT_REF: process.env.COMMIT_REF || 'local',
  })
})

const inIframe = () => {
  try {
    return window.self !== window.top
  } catch (e) {
    return true
  }
}

const App = () => {
  const { dispatch } = useAuthContext()

  useEffect(() => {
    const jwt = window.localStorage.getItem('auth_jwt')
    if (jwt) {
      dispatch({ type: 'authenticated_user' })

      const verRef = process.env.COMMIT_REF || 'local'
      // clear cache on new ver push
      if (window.localStorage.getItem('VER_REF') !== verRef) {
        window.localStorage.setItem('VER_REF', verRef)

        console.info('clearing cache')
        Object.keys(localStorage).reduce((_, key) => {
          if (key && key.includes('cache')) {
            localStorage.removeItem(key)
          }
        })

      }
    }

    const isInIframe = inIframe()
    if (isInIframe) InitStorageHost('atom')
  }, [dispatch])



  return (
    <Router>
      <div>
        <Switch>
          <Route path='/login' component={CommonLogin} />
          <Route component={LoggedInContainer} />
        </Switch>
      </div>
    </Router>
  )
}

export default hot(module)(App)
