import React, { useState, useEffect, useCallback, Suspense, lazy } from 'react'
import ReactRouterPropTypes from 'react-router-prop-types'

import { Route, Redirect, Switch, withRouter } from 'react-router-dom'
import { Grid } from 'semantic-ui-react'
import { toast, ToastContainer } from 'react-toastify'
import axios from 'axios'
import { Helmet } from 'react-helmet'
import moment from 'moment'
import { configureScope, captureException } from '@sentry/browser'
import { useAuthContext, InitStorage } from '@eqworks/common-login'
import { makeStyles } from '@material-ui/core/styles'
import 'react-toastify/dist/ReactToastify.min.css'

import ScopedCssBaseline from '@material-ui/core/ScopedCssBaseline'
import { ThemeProvider, DefaultTheme } from '@eqworks/lumen-ui/dist/theme'

import ErrorOrRoute from '../common-components/error-route'
import MenuBar from './menu-bar'
import getAccess from '../utils/auth'
import getTheme from '../semantic-themes/get-theme'
import MetrolandIcon from '../static/favicon/metroland.png'
import JMIcon from '../static/favicon/JM_Favicon_125x125.png'


const UserContainer = lazy(() => import('../user/user-container'))
const Z00M = lazy(() => import('../z00m'))
const OverlordTable = lazy(() => import('../overlord-table'))
const WhiteLabels = lazy(() => import('../white-labels'))
const WhiteLabel = lazy(() => import('../whitelabel/white-label'))
const Whitelist = lazy(() => import('../whitelist'))
const Customer = lazy(() => import('../customer/customer'))
const Campaign = lazy(() => import('../campaign/campaign'))
const ErrorPage = lazy(() => import('../common-components/error-page'))
const RTBInsights = lazy(() => import('../rtb-insights'))

const MAINT = 'https://ojuoz0bs90.execute-api.us-east-1.amazonaws.com/dev/maintenance?service=atom'

const propTypes = {
  history: ReactRouterPropTypes.history.isRequired,
  location: ReactRouterPropTypes.location.isRequired,
}

const inIframe = () => {
  try {
    return window.self !== window.top
  } catch (e) {
    return true
  }
}

const getCustomFavicon = (access) => {
  const jm = ['juicemobile.com']
  let href
  if (jm.some((s) => window.location.hostname.toLowerCase().includes(s))) {
    href = JMIcon
  } else if (access && Array.isArray(access.wl) && access.wl.length === 1) {
    if (access.wl[0] === 9) {
      href = MetrolandIcon
    } else if (access.wl[0] === 873) {
      href = JMIcon
    }
  }
  return href ? (<Helmet><link rel='shortcut icon' href={href} /></Helmet>) : null
}

const useStyles = makeStyles(() => ({
  grid: { margin: '0 0 !important' },
  mainBody: {
    marginTop: '80px !important',
    padding: '0 0 0 90px !important',
    width: '100%',
  },
  tab: { zIndex: 2000 },
  root: {
    backgroundColor: '#FFF',
    '& span': {
      fontSize: 14,
    },
    '& .MuiInputBase-root': {
      padding: '4px 12px',
    },
  },
}))

const LoggedInContainer = ({ history, location }) => {
  const classes = useStyles()

  const { authState: { authenticated }, authActions, dispatch } = useAuthContext()
  const { logOut } = useCallback(authActions, [])
  const [state, setState] = useState({})

  const checkMaint = async () => {
    // check last check timestamp to see if need to call api again
    // skip checking if less than 24hr since last check
    let maintToast
    const { maintCheckTS = 0 } = window.localStorage
    const timeDiff = (Date.now() / 1000) - maintCheckTS
    if (timeDiff < 24 * 60 * 60) {
      return
    }

    try {
      const { data } = await axios.get(MAINT)
      if (data) {
        const { start, end, message = '' } = data
        if (!start) {
          console.error('maint response without start timestamp')
          return
        }
        // record valid check timestamp
        window.localStorage.setItem('maintCheckTS', Date.now() / 1000)

        if (end && end < Date.now() / 1000) {
          return
        }

        const startTime = moment(start, 'X').format('LLL')
        const endTime = moment(end, 'X').format('LLL')

        if (!maintToast) {
          maintToast = toast.info(
            (
              <div>
                <h3>Maintenance</h3>
                <div>{message}</div>
                <div>Start: {startTime}</div>
                {end ? (<div>End: {endTime}</div>) : ''}
              </div>
            ),
            {
              position: 'bottom-left',
              autoClose: false,
              closeOnClick: false,
            },
          )
        }
      }
    } catch (error) {
      if (error.response && error.response.status === 404) {
        // record valid check timestamp
        window.localStorage.setItem('maintCheckTS', Date.now() / 1000)
      } else {
        console.error(error)
      }
    }
  }

  const checkAuthAndRedirect = useCallback(() => {
    if (!authenticated) {
      // eslint-disable-next-line max-len
      const encodedPathName = encodeURIComponent(`${location.pathname}${location.search}${location.hash}`)
      history.replace(`/login?origin=${encodedPathName}`)
    } else {
      configureScope((scope) => {
        scope.setUser({ id: window.localStorage.auth_user })
      })
    }
  }, [authenticated, history, location])

  useEffect(() => {
    checkAuthAndRedirect()
    checkMaint()
    setState({ errorCode: null })

    axios.interceptors.response.use(null, (error) => {
      // handle 401 and 403
      if (error.response) {
        if ([403, 404].includes(error.response.status)) {
          setState({ errorCode: error.response.status })
        } else if (error.response.status === 401) {
          const searchParams = new URLSearchParams(location.search.slice(1))
          // return if the request is a redirect already
          if (searchParams.has('redirect')) {
            return
          }

          logOut()
          // eslint-disable-next-line max-len
          const encodedPathName = encodeURIComponent(`${location.pathname}${location.search}${location.hash}`)
          history.replace(`/login?origin=${encodedPathName}&redirect`)
          return
        }
      }
      // channel to sentry
      captureException(error)
      // eslint-disable-next-line consistent-return
      return Promise.reject(error)
    })

    // identify and update user email for inspectlet
    const access = getAccess()
    if (Object.prototype.hasOwnProperty.call(window, '__insp')) {
      window.__insp.push(['identify', window.localStorage.auth_user])
      window.__insp.push(['tagSession', { access }])
    }
  }, [checkAuthAndRedirect, logOut, history, location])

  const handleLogOut = () => {
    logOut()
    configureScope((scope) => {
      scope.setUser({ id: '' })
    })
  }

  const handleLogOutAll = () => {
    logOut()
    configureScope((scope) => {
      scope.setUser({ id: '' })
    })
    const isInIframe = inIframe()
    if (!isInIframe) {
      InitStorage('atom', ['auth_jwt', 'auth_user', 'auth_access'], 'remove')
        .catch((e) => console.error(e))
        .finally(() => dispatch({ type: 'auth_loading', isLoading: false }))
      dispatch({ type: 'auth_loading', isLoading: true, message: 'Logging out...' })
    }
  }

  if (!authenticated) return null

  const { errorCode } = state
  const access = getAccess()
  const theme = getTheme()

  return (
    <div className={theme}>
      {getCustomFavicon(access)}
      <ThemeProvider theme={DefaultTheme}>
        <ScopedCssBaseline className={classes.root}>
          <Grid className={classes.grid}>
            <MenuBar
              history={history}
              location={location}
              logOut={handleLogOut}
              logOutAll={handleLogOutAll}
              access={access}
            />
            <div className={classes.mainBody}>
              <ToastContainer
                className={classes.tab}
                position='top-center'
                type='default'
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                pauseOnHover
              />
              <Suspense fallback={<div>Loading...</div>}>
                <Switch>
                  <Route exact path='/' render={() => (<Redirect to='/whitelabels' />)} />
                  <ErrorOrRoute exact path='/users' component={UserContainer} errorCode={errorCode} />
                  <ErrorOrRoute exact path='/z00m' component={Z00M} errorCode={errorCode} />
                  <ErrorOrRoute exact path='/overlordtable' component={OverlordTable} errorCode={errorCode} />
                  <ErrorOrRoute exact path='/whitelabels' component={WhiteLabels} errorCode={errorCode} />
                  <ErrorOrRoute exact path='/globalwhitelist' component={Whitelist} errorCode={errorCode} />
                  <ErrorOrRoute path='/whitelabel/:wlid' component={WhiteLabel} errorCode={errorCode} />
                  <ErrorOrRoute path='/customer/:cuid' component={Customer} errorCode={errorCode} />
                  <ErrorOrRoute path='/campaign/:id' component={Campaign} errorCode={errorCode} />
                  <ErrorOrRoute exact path='/rtb/:type' component={RTBInsights} errorCode={errorCode} />
                  <Route render={() => (<ErrorPage errorCode={404} />)} />
                </Switch>
              </Suspense>
            </div>
          </Grid>
        </ScopedCssBaseline>
      </ThemeProvider>
    </div>
  )
}

LoggedInContainer.propTypes = propTypes

export default withRouter(LoggedInContainer)
