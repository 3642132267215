import { combineReducers } from 'redux'


function isEditing(state = 0, action) {
  switch (action.type) {
    case 'OPEN_EDIT':
      return state + 1
    case 'CLOSE_EDIT':
      return state - 1
    case 'RESET_EDIT':
      return 0
    default:
      return state
  }
}

export default combineReducers({ isEditing })
