import React, { useState, useMemo } from 'react'
import ReactRouterPropTypes from 'react-router-prop-types'
import PropTypes from 'prop-types'

import { Link } from 'react-router-dom'
import { Menu, Dropdown, Grid, Image, Label } from 'semantic-ui-react'
import copy from 'copy-to-clipboard'
import { makeStyles } from '@material-ui/core/styles'

import Snowfall from 'react-snowfall'

import Search from '../search'
import { isFestivalSeason } from '../utils/festival'
import AtomLogo from '../static/logo/atom.png'
import ATOMEaster from '../static/logo/atom_easter_2022.png'
import ATOMHalloween from '../static/logo/atom_halloween_2021.png'
import AtomWinter from '../static/logo/atom_raindeer_2021.png'
import MetrolandLogo from '../static/logo/metroland.png'
import AdloopLogo from '../static/logo/adloop.png'
import JMLogo from '../static/logo/JM_White.png'


const propTypes = {
  location: ReactRouterPropTypes.location.isRequired,
  logOut: PropTypes.func.isRequired,
  logOutAll: PropTypes.func.isRequired,
  access: PropTypes.object.isRequired,
}

const MAIN_VERSION = 'V3.x'
// COMMIT_REF is a Git metadata environment variable from Netlify
// VERCEL_GIT_COMMIT_SHA is the similar one from Vercel
const COMMIT_REF = process.env.VERCEL_GIT_COMMIT_SHA || process.env.COMMIT_REF || 'local'

const showUser = ({ read, write }) => (read === -1 || read >= 1000)
  && (write === -1 || write >= 1000)

const showWhitelist = ({ read, write }) => (read === -1 || read >= 1000)
  && (write === -1 || write >= 1000)

const showOverlordTable = ({ read, write }) => (read === -1 || read >= 100)
  && (write === -1 || write >= 100)

const showZ00M = ({ wl, customers }) => wl === -1 && customers === -1

const isEasterSeason = isFestivalSeason('Easter')
const isHalloweenSeason = isFestivalSeason('Halloween')
const isWinterHolidays = isFestivalSeason('Winter Holidays')

const useStyles = makeStyles(() => ({
  devIndicator: {
    transform: 'rotate(-45deg)',
    paddingRight: '1rem',
  },
  outerMenu: {
    padding: '0 0 !important',
    boxShadow: 'none !important',
  },
  navBar: {
    width: '100%',
    padding: '0 0 !important',
    height: '55px !important',
    borderRadius: '0 !important',
    boxShadow: 'none !important',
  },
  logo: {
    padding: '0.2rem 1rem 0px 1rem',
    width: '128px',
    height: '55px',
    '& > img': {
      maxHeight: '100%',
    },
  },
  search: { marginLeft: '1rem' },
  menuItem: {
    fontSize: '16px',
    paddingLeft: '0.8rem !important',
    paddingRight: '0.8rem !important',
  },
  navDropDown: {
    padding: '0.7rem 0',
    fontSize: '16px',
  },
  dropdownDivider: { margin: '0px !important' },
  versionTextGroup: { paddingTop: '0.4rem !important' },
  versionLinkHidden: {
    visibility: 'hidden',
    fontSize: '80% !important',
    fontWeight: '100 !important',
  },
  versionLink: {
    fontSize: '80% !important',
    fontWeight: '100 !important',
  },
  versionText: {
    fontSize: '80% !important',
    fontWeight: '100 !important',
    opacity: '0.45 !important',
  },
}))

const MenuBar = ({ location, logOut, logOutAll, access }) => {
  const classes = useStyles()
  const [isVersionHover, setHover] = useState(false)
  const [showCopied, setCopied] = useState(false)

  const onMouseOver = () => {
    setHover(true)
  }

  const onMouseLeave = () => {
    setHover(false)
  }

  const onVersionClick = () => {
    copy(`
      ${MAIN_VERSION}
      API: ${process.env.REACT_APP_API_VER}
      Keywarden: ${process.env.REACT_APP_KEY_WARDEN_STAGE}
      Commit: ${COMMIT_REF.substring(0, 7)}
    `)
    setCopied(true)
    window.setTimeout(() => {
      setCopied(false)
    }, 5000)
  }

  const renderVersion = () => (
    <Dropdown.Item
      onClick={onVersionClick}
      className={classes.versionTextGroup}
      onMouseOver={onMouseOver}
      onMouseLeave={onMouseLeave}
    >
      <div className={!isVersionHover ? classes.versionLinkHidden : classes.versionLink}>
        {showCopied ? 'Copied' : 'Click to copy'}
      </div>
      <div className={classes.versionText}>
        {MAIN_VERSION}
      </div>
      <div className={classes.versionText}>
        API:
        {' '}
        {process.env.REACT_APP_API_VER}
      </div>
      <div className={classes.versionText}>
        Keywarden:
        {' '}
        {process.env.REACT_APP_KEY_WARDEN_STAGE}
      </div>
      <div className={classes.versionText}>
        Commit:
        {' '}
        {COMMIT_REF.substring(0, 7)}
      </div>
    </Dropdown.Item>
  )

  const jm = ['juicemobile.com']

  const logoSrc = useMemo(() => {
    if (isEasterSeason()) {
      return ATOMEaster
    }
    if (isHalloweenSeason()) {
      return ATOMHalloween
    }
    if (isWinterHolidays()) {
      return AtomWinter
    }
    if (jm.some((s) => window.location.hostname.toLowerCase().includes(s))) {
      return JMLogo
    } else if (Array.isArray(access.wl) && access.wl.length === 1) {
      if (access.wl[0] === 9) {
        return MetrolandLogo
      }
      if (access.wl[0] === 2) {
        return AdloopLogo
      }
      if (access.wl[0] === 873) {
        return JMLogo
      }
    }
    return AtomLogo
  }, [jm, access.wl])

  return (
    <Menu as={Grid.Row} fixed='top' className={classes.outerMenu}>
      {process.env.REACT_APP_API_VER === 'dev' && (
        <Label color='yellow' corner='left'>
          <p className={classes.devIndicator}>DEV</p>
        </Label>
      )}
      <Menu inverted borderless className={classes.navBar} size='massive'>
        <Menu.Item fitted>
          <Image as={Link} to='/' className={classes.logo} src={logoSrc} alt='Atom Logo' />
        </Menu.Item>
        <Menu.Item fitted className={classes.search}>
          <Search />
        </Menu.Item>
        <Menu.Item fitted position='right'>
          {showZ00M(access) && (
            <Menu.Item
              className={classes.menuItem}
              as={Link}
              to='/z00m'
              name='z00m'
              content='Z00M'
              position='right'
              active={location.pathname === '/z00m'}
            />
          )}
          {showOverlordTable(access) && (
            <Menu.Item
              className={classes.menuItem}
              as={Link}
              to='/overlordtable'
              name='overlordtable'
              content='Overlord Table'
              position='right'
              active={location.pathname === '/overlordtable'}
            />
          )}
          {showWhitelist(access) && (
            <Menu.Item
              className={classes.menuItem}
              as={Link}
              to='/globalwhitelist'
              name='globalwhitelist'
              content='Global Whitelist'
              position='right'
              active={location.pathname === '/globalwhitelist'}
            />
          )}
          {showUser(access) && (
            <Menu.Item
              className={classes.menuItem}
              as={Link}
              to='/users'
              name='users'
              content='Users'
              position='right'
              active={location.pathname === '/users'}
            />
          )}
          <Menu.Item>
            <Dropdown
              className={classes.navDropDown}
              text={window.localStorage.getItem('auth_user')}
              simple
            >
              <Dropdown.Menu>
                <Dropdown.Item onClick={logOut}>Logout</Dropdown.Item>
                <Dropdown.Item onClick={logOutAll}>Logout All</Dropdown.Item>
                <Dropdown.Divider className={classes.dropdownDivider} />
                {renderVersion()}
              </Dropdown.Menu>
            </Dropdown>
          </Menu.Item>
        </Menu.Item>
      </Menu>
      {isWinterHolidays() &&
        <Snowfall
          color='#fefefe'
          speed={[0.5, 1.0]}
          snowflakeCount={20}
        />
      }
    </Menu>
  )
}

MenuBar.propTypes = propTypes

export default MenuBar
