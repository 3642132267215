.leftSquare {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.rightSqure {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border-right-color: transparent !important;
}

.leftDropdown {
  composes: rightSqure;
  min-width: 7em !important;
}

.timezoneLeft {
  font-size: 1rem !important;
}

.timezoneRight {
  font-size: 1rem !important;
  font-weight: unset !important;
}

.name {
  width: 40rem !important;
}

.typeLabel {
  padding: .78571429em 1.5em .78571429em !important;
}
