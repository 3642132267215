import React from 'react'
import PropTypes from 'prop-types'

import moment from 'moment-timezone'

import InlineFormField from '../../../../common-components/inline-form-field'
import SemanticDatePicker from '../../../../common-components/semantic-date-picker'
import { DEFAULT_TZ } from '../../../../utils/time'
import styles from './duration-field.css'


const propTypes = {
  StartDate: PropTypes.string.isRequired,
  EndDate: PropTypes.string.isRequired,
  Draft: PropTypes.bool,
  errorText: PropTypes.string,
  dateHandler: PropTypes.func.isRequired,
  blockedStart: PropTypes.string,
  blockedEnd: PropTypes.string,
}

const defaultProps = {
  errorText: '',
  Draft: false,
  blockedStart: null,
  blockedEnd: null,
}

const DurationField = ({
  StartDate,
  EndDate,
  Draft,
  errorText,
  dateHandler,
  blockedStart,
  blockedEnd,
}) => {
  const startDate = moment.tz(StartDate, DEFAULT_TZ)
  const endDate = moment.tz(EndDate, DEFAULT_TZ)
  const momentBlockedStart = blockedStart ? moment.tz(blockedStart, DEFAULT_TZ) : blockedStart
  const momentBlockedEnd = blockedEnd ? moment.tz(blockedEnd, DEFAULT_TZ) : blockedEnd
  return (
    <InlineFormField
      label='Duration'
      errorText={errorText}
    >
      <SemanticDatePicker
        startDate={startDate}
        endDate={endDate}
        position='bottom left'
        onSelection={dateHandler}
        numberOfMonths={2}
        labelProps={{ size: 'large', className: styles.centerVertically }}
        endDateOnly={!Draft}
        blockedStart={momentBlockedStart}
        blockedEnd={momentBlockedEnd}
      />
    </InlineFormField>
  )
}

DurationField.propTypes = propTypes

DurationField.defaultProps = defaultProps

export default DurationField
