.label {
  width: 9rem !important;
  font-weight: bold !important;
  font-size: 1rem !important;
  margin-right: 0 !important;
  display: inline-flex !important;
  align-items: center !important;
}

:global(.ui.form .error.field) > .label {
  color: #9f3a38;
}

.large {
  align-items: flex-start !important;
  padding-top: 0.5rem !important;
}
