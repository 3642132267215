import React from 'react'
import PropTypes from 'prop-types'

import { Button } from 'semantic-ui-react'

import Styles from './domain-picker.css'


const DomainButtons = ({ domains, onClick, icon }) => (
  <div>
    {domains.map((d) => (
      <Button
        key={d.DomainID}
        fluid
        basic
        content={d.TLD}
        onClick={onClick(d)}
        icon={icon}
        className={Styles.domainButton}
      />
    ))}
  </div>
)
DomainButtons.propTypes = {
  domains: PropTypes.arrayOf(PropTypes.object).isRequired,
  onClick: PropTypes.func.isRequired,
  icon: PropTypes.string.isRequired,
}

export default DomainButtons
