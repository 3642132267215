import {
  AUTHENTICATED,
  UNAUTHENTICATED,
  EMAIL_ERROR,
  PC_ERROR,
  AUTH_LOADING,
  CLEAN_UP_ERROR,
} from './actions'


const authDefault = {
  authenticated: false,
  authLoading: false,
}
export default function authReducer(state = authDefault, action) {
  switch (action.type) {
    case AUTHENTICATED:
      return { ...state, authenticated: true }
    case UNAUTHENTICATED:
      return { ...state, authenticated: false }
    case EMAIL_ERROR:
      return { ...state, emailError: action.header, errorContent: action.content }
    case PC_ERROR:
      return { ...state, passcodeError: action.header, errorContent: action.content }
    case CLEAN_UP_ERROR:
      return { ...state, emailError: '', passcodeError: '', errorContent: '' }
    case AUTH_LOADING:
      return {
        ...state,
        authLoading: action.isLoading,
        loadingMessage: action.isLoading ? action.message : '',
      }
    default:
      return state
  }
}
