export const revTypes = {
  1: 'CPM',
  2: 'CPC',
  3: 'CPA',
  4: 'COST+',
}

export const human = (code) => revTypes[code] || 'Unknown Type'

export function code(type) {
  return {
    CPM: 1,
    CPC: 2,
    CPA: 3,
    'COST+': 4,
  }[type] || -1
}

export function unit(type) {
  return {
    1: 'IMPs',
    2: 'CLKs',
    3: 'ACTs',
    4: 'IMPs',
  }[type] || 'Unknown Unit'
}

export const CPM = 1
export const CPC = 2
export const CPA = 3
export const COSTPLUS = 4

export default { human, code, unit, revTypes }
