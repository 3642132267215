import moment from 'moment-timezone'
import Holidays from 'date-holidays'

const hd = new Holidays('CA')
hd.setLanguages('en')
const HOLIDAYS = hd.getHolidays(new Date().getFullYear())
const getHolidayMD = name => (
  HOLIDAYS.find(h => h.name === name)
    .date
    .split(' ')[0].split('-').splice(1).join('-')
)

export const TZ = Intl.DateTimeFormat().resolvedOptions().timeZone

export const FESTIVALS = {
  'Easter': {
    md: getHolidayMD('Easter Sunday'), // required because Easter does not fall on the same day each year
    name: 'Easter',
    before: 7, // start range
    after: 2, // end range
  },
  'Halloween': {
    md: '10-31',
    name: 'Halloween', // which covers día de los muertos
    before: 3, // start range
    after: 3, // end range
  },
  'Winter Holidays': {
    md: '12-25',
    name: 'Winter',
    before: 10, // change to 5 before merge
    after: 10,
  },
}

export const getFestival = (date = new Date()) => {
  let exact = false
  const [name] = Object.entries(FESTIVALS).find(([, { md, before, after }]) => {
    const beforeDate = moment(date).subtract(after, 'days').startOf('day')
    const afterDate = moment(date).add(before, 'days').endOf('day')
    let d = beforeDate.clone()
    let inRange = false
    while (d.isSameOrBefore(afterDate)) {
      inRange = d.format('MM-DD') === md
      if (inRange) {
        exact = d.clone().startOf('day').isSame(moment(date).startOf('day'))
        break
      }
      d.add(1, 'day')
    }
    return inRange
  }) || []
  return { name, exact }
}

export const isFestivalSeason = (festival) => (date = new Date()) => {
  const { name } = getFestival(date)
  return (name || '').toLowerCase() === (festival || '').toLowerCase()
}
