.domainSegment{
  margin-top: 0 !important;
  margin-bottom: 1.0rem !important;
}

.buttons {
  margin-bottom: 1.5rem !important;
}

.equalWidth {
  width: 0 !important;
}

.domain {
  margin-top: .5rem;
  margin-bottom: .5rem;
  margin-right: .5rem;
  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.domain:first-child {
  margin-top: 0;
}

.domain:last-child {
  margin-bottom: 0;
}

.downloadButtons {
  margin-bottom: 1.5rem;
}
