import React from 'react'
import PropTypes from 'prop-types'
import { Route } from 'react-router-dom'

import ErrorPage from './error-page'

const propTypes = {
  errorCode: PropTypes.number,
  props: PropTypes.object,
}

const ErrorOrRoute = ({ errorCode, ...props }) => {
  return errorCode
    ? (<ErrorPage errorCode={errorCode} />)
    : (<Route {...props} />)
}

ErrorOrRoute.propTypes = propTypes

export default ErrorOrRoute
