const ancestorsReducer = (state = [], action) => {
  switch (action.type) {
    case 'LOAD_ANCESTORS':
      return action.ancestors
    default:
      return state
  }
}

export default ancestorsReducer
