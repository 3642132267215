import moment from 'moment'


const MAX_CHAR_LEN = 240

// https://stackoverflow.com/questions/201323/how-can-i-validate-an-email-address-using-a-regular-expression/14075810#14075810
// eslint-disable-next-line no-control-regex
const EMAIL_REGEX = new RegExp('([-!#-\'*+/-9=?A-Z^-~]+(.[-!#-\'*+/-9=?A-Z^-~]+)*|"([]!#-[^-~ \t]|(\\[\t -~]))+")@[0-9A-Za-z]([0-9A-Za-z-]{0,61}[0-9A-Za-z])?(.[0-9A-Za-z]([0-9A-Za-z-]{0,61}[0-9A-Za-z])?)+')

export const isNumber = (value, field) => ({
  error: Number.isNaN(Number(value)),
  errorMsg: `${field} is not a number`,
})

export const isInt = (value, field) => {
  const parsed = Number(value)
  return {
    error: !Number.isInteger(parsed),
    errorMsg: `${field} must be an integer`,
  }
}

export const maxLength = (value, field) => ({
  error: value.length > MAX_CHAR_LEN,
  errorMsg: `${field} must be less than ${MAX_CHAR_LEN} characters`,
})

export const isNotEmpty = (value, field) => ({
  error: !value || (typeof value === 'string' && value.trim() === ''),
  errorMsg: `${field} cannot be empty`,
})

export const isNotNegative = (value, field) => {
  let error = true
  if (Number(value) >= 0) {
    error = false
  }

  return { error, errorMsg: `${field} cannot be negative` }
}

export const isNotZero = (value, field) => {
  let error = true
  if (Number(value) !== 0) {
    error = false
  } else if (!value) {
    error = false
  }

  return { error, errorMsg: `${field} cannot be 0` }
}

export const twoDecimals = (value, field) => {
  const parsedValue = String(value)
  let error = false
  if (parsedValue.includes('.')) {
    error = parsedValue.split('.')[1].length > 2
  }

  return { error, errorMsg: `${field} can only contain 2 decimal places` }
}

export const isDate = (value, field) => {
  const error = !moment(value).isValid()

  return { error, errorMsg: `${field} is not a valid date` }
}

export const isDateAfter = (value, field, date = moment()) => {
  const actualDate = moment(date)
  const error = moment(value).diff(actualDate) > 0

  return { error, errorMsg: `${field} has to be after ${actualDate.format('MMMM DD YYYY')}` }
}

export const isUnlimited = (value, field) => {
  if (Number(value) === -1) {
    return { error: false, errorMsg: '' }
  }

  return isNotNegative(value, field)
}

export const isNotUnlimited = (value, field) => {
  if (Number(value) === -1) {
    return { error: true, errorMsg: `${field} can not be unlimited` }
  }

  return isNotNegative(value, field)
}

export const isPercent = (value, field) => {
  if (Number(value) < 0 || Number(value) > 100) {
    return { error: true, errorMsg: `${field} must be between 0-100` }
  }

  return { error: false, errorMsg: '' }
}

export const runValidators = (value, field, funcs, canBeEmpty = false) => {
  if (canBeEmpty && isNotEmpty(value).error) {
    return { error: false, errorMsg: '' }
  }

  for (const func of funcs) {
    const { error, errorMsg } = func(value, field)
    if (error) return { error, errorMsg }
  }

  return { error: false, errorMsg: '' }
}

export const isValidArray = (values, field, choices = []) => {
  if (!Array.isArray(values)) {
    return { error: true, errorMsg: `${field} must be an array` }
  }
  if (choices.length > 0 && values.every((v) => choices.includes(v))) {
    return { error: false, errorMsg: '' }
  }
  return { error: true, errorMsg: `${field} values are not valid` }
}

export const isValidString = (value, field, choices = []) => {
  if (choices.length > 0 && choices.includes(value)) {
    return { error: false, errorMsg: '' }
  }
  return { error: true, errorMsg: `${field} value is not valid` }
}

export const isBinary = (value, field) => {
  if (value.split('').every((v) => (v === '0' || v === '1'))) {
    return { error: false, errorMsg: '' }
  }
  return { error: true, errorMsg: `${field} is not binary` }
}

export const isCorrectLength = (value, field, length) => {
  if (value.length === length) {
    return { error: false, errorMsg: '' }
  }
  return { error: true, errorMsg: `${field} length must be ${length}` }
}

export const isEmailAddress = (value, field) => {
  const valid = EMAIL_REGEX.test(value)
  return { error: !valid, errorMsg: valid ? '' : `${field} is not a valid address` }
}
