import 'core-js/stable'
import 'regenerator-runtime/runtime'

import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'

import { LoginContextProvider } from '@eqworks/common-login'

import Snowfall from 'react-snowfall'

import './index.css'
import App from './app/app'
import { unregister } from './utils/register-service-worker'
import configureStore from './configure-store'
import { isFestivalSeason } from './utils/festival'


const store = configureStore()
const isWinterHolidays = isFestivalSeason('Winter Holidays')

ReactDOM.render(
  <Provider store={store}>
    <LoginContextProvider stage={process.env.REACT_APP_KEY_WARDEN_STAGE} host={process.env.REACT_APP_KEY_WARDEN_HOST}>
      <App />
    </LoginContextProvider>
    {isWinterHolidays() &&
      <Snowfall
        color='#fefefe'
        speed={[0.5, 1.0]}
        snowflakeCount={150}
      />
    }
  </Provider>,
  document.getElementById('root'),
)

unregister()
