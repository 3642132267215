import moment from 'moment'


const INITIAL_STATE = {
  modalIsVisible: false,
  isLoading: false,
  isScrollLoading: false,
  logs: [],
  total: 0,
  filters: {
    startDate: moment().subtract(7, 'days'),
    endDate: moment(),
    count: 15,
    nextCursor: null,
    previousCursor: null,
    direction: null,
    focusedInput: null,
  },
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'AUDIT_OPEN_MODAL':
      return { ...state, modalIsVisible: true, isLoading: true }
    case 'AUDIT_SCROLL_LOADING':
      return { ...state, isScrollLoading: true }
    case 'AUDIT_CLOSE_MODAL':
      return {
        ...state,
        modalIsVisible: false,
        logs: INITIAL_STATE.logs,
        filters: INITIAL_STATE.filters,
      }
    case 'AUDIT_FETCH_DATA_SUCCESS':
      return {
        ...state,
        isLoading: false,
        isScrollLoading: false,
        logs: [...state.logs, ...action.logs],
        total: action.total,
        filters: {
          ...state.filters,
          nextCursor: action.nextCursor,
          previousCursor: action.previousCursor,
        },
      }
    case 'AUDIT_FETCH_DATA_ERROR':
      return { ...state, isLoading: false, isScrollLoading: false, logs: INITIAL_STATE.logs }
    case 'AUDIT_CHANGE_FILTERS':
      return { ...state, filters: action.filters }
    case 'AUDIT_CHANGE_FILTERS_AND_RESET':
      return { ...state, filters: action.filters, isLoading: true, logs: INITIAL_STATE.logs }
    default:
      return state
  }
}
