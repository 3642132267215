import React from 'react'
import PropTypes from 'prop-types'
import ReactRouterPropTypes from 'react-router-prop-types'

import { Link, withRouter } from 'react-router-dom'
import { Icon, Sidebar, Menu } from 'semantic-ui-react'

import Styles from './side-nav.css'


const propTypes = {
  navigations: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
  })).isRequired,
  location: ReactRouterPropTypes.location.isRequired,
  children: PropTypes.node.isRequired,
}

const SideNav = ({ navigations, location, children }) => (
  <div>
    <Sidebar
      as={Menu}
      direction='left'
      animation='overlay'
      width='thin'
      visible
      icon='labeled'
      vertical
      className={Styles.sidebar}
    >
      {navigations.map((menuItem) => (
        <Menu.Item
          as={location.pathname === menuItem.link ? null : Link}
          to={menuItem.link}
          name={menuItem.name}
          active={location.pathname === menuItem.link}
          key={menuItem.name}
        >
          <Icon name={menuItem.icon} />
          {menuItem.name}
        </Menu.Item>
      ))}
    </Sidebar>
    <Sidebar.Pusher>
      {children}
    </Sidebar.Pusher>
  </div>
)

SideNav.propTypes = propTypes

export default withRouter(SideNav)
