import getAccess from '../utils/auth'


export default () => {
  // return 'metroland'
  const { wl } = getAccess()

  if (wl === -1) {
    return ''
  } if (wl.length === 1 && wl[0] === 9) {
    // if only metroland
    return 'metroland'
  }

  return ''
}
