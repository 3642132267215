import moment from 'moment-timezone'


const DEFAULT_TZ = 'America/Toronto'

const fromNow = (dt, tz = DEFAULT_TZ) => moment.tz(dt, tz).diff(moment(), 'days')

const beforeNow = (dt, tz = DEFAULT_TZ) => moment.tz(dt, tz).isBefore(moment.tz(tz || DEFAULT_TZ))

// const getUserLocale = () => window.navigator.language ||
//   window.navigator.languages[0] ||
//   window.navigator.browserLanguage ||
//   window.navigator.userLanguage

const formatDateToLocale = (dt, tz = DEFAULT_TZ) => (
  moment.tz(dt, tz).toDate().toLocaleDateString(undefined, { timeZone: tz })
)

const formatTimeToLocale = (dt, tz = DEFAULT_TZ) => (
  moment.tz(dt, tz).toDate().toLocaleString(undefined, { timeZone: tz })
)

const percentageProgress = (start, end, tz = DEFAULT_TZ) => {
  const parsedStart = moment.tz(start, tz)
  const parsedEnd = moment.tz(end, tz)

  const elaspedTime = moment().diff(parsedStart, 'seconds')
  const totalDuration = parsedEnd.diff(parsedStart, 'seconds')

  return Math.floor(elaspedTime / totalDuration * 100)
}

const getDuration = (start, end) => moment(end).diff(moment(start), 'days')

const timezones = moment.tz.names()

const unixStringToDate = (unixTimeStampString) => (
  moment.tz(unixTimeStampString, moment.tz.guess()).format('YYYY-MM-DD HH:mm:ss'))

// return start/end as moment object capping at today
// timeZone is ignroed when supplied start and end are moment obj
const getStartEnd = ({ startDate, endDate, timeZone = DEFAULT_TZ }) => {
  let start
  if (moment.isMoment(startDate)) {
    start = moment(startDate)
  } else {
    start = moment.tz(startDate, timeZone)
  }
  let end
  if (moment.isMoment(endDate)) {
    end = moment(endDate)
  } else {
    end = moment.tz(endDate, timeZone).endOf('day')
  }
  const now = moment()
  if (now.isBefore(end)) {
    end = now
  }
  return { start, end }
}

// generate array of days based on given start/end
const getDays = ({ startDate, endDate, timeZone = DEFAULT_TZ }) => {
  const startEnd = getStartEnd({ startDate, endDate, timeZone })
  let { start } = startEnd
  const { end } = startEnd
  const days = []
  while (start.isSameOrBefore(end)) {
    const dayStart = moment(start)
    let dayEnd = moment(dayStart).endOf('day')
    if (dayEnd.isAfter(end)) {
      dayEnd = moment(end)
    }
    days.push({
      start: dayStart,
      end: dayEnd,
    })
    start = moment(dayEnd).startOf('day').add(1, 'day')
  }
  return days
}

// generate array of hours based on given start/end
const getHours = ({ startDate, endDate, timeZone = DEFAULT_TZ }) => {
  const startEnd = getStartEnd({ startDate, endDate, timeZone })
  let { start } = startEnd
  const { end } = startEnd
  const hours = []
  while (start.isSameOrBefore(end)) {
    const hourStart = moment(start)
    let hourEnd = moment(hourStart).endOf('hour')
    if (hourEnd.isAfter(end)) {
      hourEnd = moment(end)
    }
    hours.push({
      start: hourStart,
      end: hourEnd,
    })
    start = moment(hourEnd).startOf('hour').add(1, 'hour')
  }
  return hours
}

const waitForMs = async (milliseconds) => new Promise((resolve) => setTimeout(resolve, milliseconds))

export {
  moment,
  DEFAULT_TZ,
  fromNow,
  beforeNow,
  formatTimeToLocale,
  percentageProgress,
  timezones,
  getDuration,
  formatDateToLocale,
  unixStringToDate,
  getStartEnd,
  getDays,
  getHours,
  waitForMs,
}
