/* atom-breadcrumb/index.js */
import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Link, withRouter } from 'react-router-dom'
import { Breadcrumb, Loader } from 'semantic-ui-react'

import { loadAncestors } from './actions'
import { truncate } from '../utils/util-functions'
import { getLevelName } from '../utils/level-to-name'
import { overseer } from '../utils/api'


const ancestorLevelToPathMap = {
  wls: 'whitelabels',
  wl: 'whitelabel',
  customer: 'customer',
  offer: 'campaign',
  flight: 'campaign',
  mb: 'campaign',
}

const propTypes = {
  location: PropTypes.object.isRequired,
  loadAncestors: PropTypes.func.isRequired,
}

const getTitle = (levelObj) => {
  let level = ancestorLevelToPathMap[levelObj.level]
  if (level === 'campaign') {
    level = getLevelName(levelObj.level)
  }
  level = level.charAt(0).toUpperCase() + level.slice(1)
  return `${level} | ${levelObj.name}`
}

class AtomBreadcrumb extends Component {
  constructor(props) {
    super(props)

    this.state = {
      ancestors: [],
      loading: false,
    }
  }

  componentDidMount() {
    this.loadAncestors()
  }

  componentDidUpdate(prevProps) {
    // refresh data if path is changed
    const { location: { pathname } } = this.props
    const url = pathname.split('/').filter((s) => s)
    const prevUrl = prevProps.location.pathname.split('/').filter((s) => s)
    if (url[0] !== prevUrl[0] || url[1] !== prevUrl[1]) {
      this.loadAncestors(url)
    }
  }

  loadAncestors = (url = null) => {
    const { location: { pathname }, loadAncestors } = this.props
    const [type = null, id = null] = url || pathname.split('/').filter((s) => s)

    if (type === null && id === null) return

    const newAncestors = [{
      name: 'All Whitelabels',
      level: 'wls',
    }]

    if (['whitelabel', 'customer', 'campaign'].includes(type)) {
      this.setState({ loading: true })
      overseer({
        url: `/${type}s/${id}`,
        method: 'get',
      }).then((response) => {
        // build up newAncestors from api returned ancestors
        // copy ancestors data from response.data.ancestor
        if ('ancestors' in response.data) {
          response.data.ancestors.forEach((ancestor) => {
            newAncestors.push({
              name: ancestor.name,
              id: ancestor.ID,
              level: ancestor.level,
            })
          })
        }
        // exact ancestor data from response.data.metadata section
        switch (type) {
          case 'whitelabel':
            newAncestors.push({
              name: response.data.Company,
              id: response.data.WhiteLabelID,
              level: 'wl',
            })
            break
          case 'customer':
            newAncestors.push({
              name: response.data.customer.CustomerName,
              id: response.data.customer.CustomerID,
              level: 'customer',
            })
            break
          case 'campaign':
            newAncestors.push({
              name: response.data.metadata.Name,
              id: response.data.metadata.CampCode,
              level: response.data.metadata.Level,
            })
            break
          default:
            break
        }
        this.setState({ ancestors: newAncestors, loading: false })
        window.document.title = getTitle(newAncestors.slice(-1)[0])
        loadAncestors(newAncestors)
      }).catch((err) => {
        console.error(err)
        this.setState({ loading: false })
      })
    } else if (type === 'whitelabels') {
      window.document.title = 'All Whitelabels'
      this.setState({ ancestors: newAncestors })
    } else {
      window.document.title = type
      this.setState({ ancestors: [{ name: type.charAt(0).toUpperCase() + type.slice(1) }] })
    }
  }

  render() {
    const {
      loading,
      ancestors,
    } = this.state

    if (loading) {
      return (
        <Loader active={loading} inline size='tiny' />
      )
    } if (ancestors.length === 0) {
      return null
    }
    const breadCrumbArray = []

    const { search } = window.location
    if (search) {
      if (search.includes('learning')) {
        ancestors.push({ name: 'Learning', level: 'mb' })
      } else if (search.includes('optimized')) {
        ancestors.push({ name: 'Optimized', level: 'mb' })
      }
    }

    ancestors.slice(0, -1).forEach((ancestor) => {
      let link
      if (ancestor.level === 'wls') {
        link = `/${ancestorLevelToPathMap[ancestor.level]}`
      } else {
        link = `/${ancestorLevelToPathMap[ancestor.level]}/${ancestor.id}`
      }
      breadCrumbArray.push((
        <Breadcrumb.Section as={Link} to={link} key={link}>
          {truncate(ancestor.name, 40)}
        </Breadcrumb.Section>
      ))
      breadCrumbArray.push(<Breadcrumb.Divider icon='right angle' key={`${link}d`} />)
    })
    // push last breadcrumb item as active
    const lastItem = ancestors[ancestors.length - 1]
    const lastItemName = truncate(lastItem.name, 40)
    let levelAndCode = []
    if (getLevelName(lastItem.level)) {
      levelAndCode.push(getLevelName(lastItem.level))
    }
    if (lastItem.id) {
      levelAndCode.push(lastItem.id)
    }
    if (levelAndCode.length > 0) {
      levelAndCode = ` (${levelAndCode.join(' ')})`
    }

    breadCrumbArray.push((
      <Breadcrumb.Section active key={lastItemName}>
        {lastItemName}
        {levelAndCode}
      </Breadcrumb.Section>
    ))

    return (
      <Breadcrumb size='large'>
        {breadCrumbArray}
      </Breadcrumb>
    )
  }
}

AtomBreadcrumb.propTypes = propTypes

const mapDispatchToProps = (dispatch) => bindActionCreators({ loadAncestors }, dispatch)

export default withRouter(connect(null, mapDispatchToProps)(AtomBreadcrumb))
