const levelToText = {
  wl: 'White Label',
  customer: 'Customer',
  flight: 'Flight',
  offer: 'Campaign',
  mb: 'Media Buy',
}

const levelToChild = {
  flight: 'Media Buy',
  offer: 'Flight',
}

const searchIndexLevelMap = {
  'White Labels': 'wl',
  Customers: 'cu',
  Campaigns: 'offer',
  Flights: 'flight',
  'Media Buys': 'mb',
}

const searchIndexLevelToRouteMap = {
  wl: 'whitelabel',
  cu: 'customer',
  offer: 'campaign',
  flight: 'campaign',
  mb: 'campaign',
}

export const getLevelName = (level) => levelToText[level]

export const getChildName = (level) => levelToChild[level]

export { searchIndexLevelMap, searchIndexLevelToRouteMap }
