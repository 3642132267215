import { combineReducers } from 'redux'
import childrenReducer from './children-table/children-table-reducer'
import manageReducer from './manage/manage-reducer'
import creativesReducer from './manage/creatives/creatives-reducer'
import reportReducer from './report/report-reducer'


export function campaignHasErrored(state = false, action) {
  switch (action.type) {
    case 'CAMPAIGN_HAS_ERRORED':
      return action.hasErrored
    default:
      return state
  }
}

export function campaignIsLoading(state = true, action) {
  switch (action.type) {
    case 'CAMPAIGN_IS_LOADING':
      return action.isLoading
    default:
      return state
  }
}
export function campaignData(state = {}, action) {
  switch (action.type) {
    case 'CAMPAIGN_FETCH_SUCCESS':
      return action.payload
    case 'UPDATE_MANAGE_LEARNING_FIELDS': {
      const newLearning = { ...state.learning, ...action.payload }
      return { ...state, learning: newLearning }
    }
    case 'UPDATE_MANAGE_TARGETING_FIELDS': {
      const newTargeting = { ...state.targeting, ...action.payload }
      return { ...state, targeting: newTargeting }
    }
    case 'UPDATE_MANAGE_DELIVERY_FIELDS': {
      const newDelivery = { ...state.delivery, ...action.payload }
      return { ...state, delivery: newDelivery }
    }
    case 'UPDATE_MANAGE_METADATA_FIELDS': {
      const newMetadata = { ...state.metadata, ...action.payload }
      return { ...state, metadata: newMetadata }
    }
    case 'NEW_CAMPAIGN_FETCH':
      return {}
    default:
      return state
  }
}

export function currentCampCode(state = null, action) {
  switch (action.type) {
    case 'POST_NEW_CAMPAIGN':
      return action.payload.currentCampCode
    default:
      return state
  }
}

const campaignReducer = combineReducers({
  currentCampCode,
  campaignData,
  campaignIsLoading,
  campaignHasErrored,

  campaignChildren: childrenReducer,
  manage: manageReducer,
  creatives: creativesReducer,
  report: reportReducer,
})

export default campaignReducer
