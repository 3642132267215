const indexReducer = (state = {}, action) => {
  switch (action.type) {
    case 'LOAD_INDEX':
      return action.index
    default:
      return state
  }
}

export default indexReducer
