.dropzone {
  width: 100%;
  padding: 3.5rem 3.5rem;
  border-width: 1px;
  border-color: rgb(102, 102, 102);
  border-style: dashed;
  border-radius: 5px;
}

.dropzoneActive {
  border-color: rgb(102, 204, 102);
  border-style: solid;
  background-color: rgb(238, 238, 238);
}

.dropzoneReject {
  border-color: rgb(204, 102, 102);
  border-style: solid;
  background-color: rgb(238, 238, 238);
}

.dropzoneDesc {
  margin: 40px 0px;
  font-size: 20px;
  text-align: center;
  color: #646C7F;
  font-weight: 800;
}

dropzoneSmallDesc {
  margin: 2rem 0px;
  font-size: 16px;
  text-align: center;
  color: #646C7F;
  font-weight: 800;
}

.dropzoneNote {
  margin-top: 1.5rem !important;
  font-weight: 100;
  font-size: 16px;
}

.dropzoneSmall {
  position: relative;
  text-align: center;
  padding: 2rem;
  border-width: 1px;
  border-color: rgb(102, 102, 102);
  border-style: dashed;
  border-radius: 5px;
  width: 100%;
}

.dropzoneSubNote {
  margin-top: 1.5rem !important;
  font-size: small;
  font-weight: normal;
  text-align: justify;
  font-style: italic;
}
