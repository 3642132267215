import React, { useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import ReactRouterPropTypes from 'react-router-prop-types'

import { Login,  useAuthContext, InitStorage } from '@eqworks/common-login'

import JMIcon from '../static/favicon/JM_Favicon_125x125.png'
import { resetJWT } from '../utils/api'


const propTypes = { history: ReactRouterPropTypes.history.isRequired }

const inIframe = () => {
  try {
    return window.self !== window.top
  } catch (e) {
    return true
  }
}

const CommonLogin = ({ history }) => {
  const { authState: { authenticated }, authActions, dispatch } = useAuthContext()

  const getCustomHead = () => {
    const jm = ['juicemobile.com']
    if (jm.some((s) => window.location.hostname.toLowerCase().includes(s))) {
      return (
        <Helmet>
          <title>Juice</title>
          <link rel='shortcut icon' href={JMIcon} />
        </Helmet>
      )
    }
    return null
  }

  useEffect(() => {
    getCustomHead()
    resetJWT()
  }, [])

  useEffect(() => {
    resetJWT()
  }, [authenticated])

  const crossLoginClick = () => {
    const isInIframe = inIframe()
    if (!isInIframe) {
      InitStorage('atom', ['auth_jwt'])
        .then(() => {
          dispatch({ type: 'authenticated_user' })
          dispatch({ type: 'clean_up_error' })
        })
        .catch((e) => {
          dispatch({
            type: 'email_error',
            header: true,
            content: 'No value found in cross storage or failed to connect. Please login with email.',
          })
          console.error(`Failed to save credentials. ${e}`)
        })
        .finally(() => dispatch({ type: 'auth_cl_loading', isLoading: false }))
    }
  }

  
  return (
    <Login product='atom' actions={authActions} history={history} crossLoginClick={crossLoginClick} />
  )
}

CommonLogin.propTypes = propTypes

export default withRouter(CommonLogin)
