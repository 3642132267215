const iabs = {
  IAB1: {
    code: 'IAB1',
    name: 'Arts & Entertainment',
    children: [
      {
        code: 'IAB1-1',
        name: 'Books & Literature',
      },
      {
        code: 'IAB1-2',
        name: 'Celebrity Fan/Gossip',
      },
      {
        code: 'IAB1-3',
        name: 'Fine Art',
      },
      {
        code: 'IAB1-4',
        name: 'Humor',
      },
      {
        code: 'IAB1-5',
        name: 'Movies',
      },
      {
        code: 'IAB1-6',
        name: 'Music',
      },
      {
        code: 'IAB1-7',
        name: 'Television',
      },
    ],
  },
  IAB2: {
    code: 'IAB2',
    name: 'Automotive',
    children: [
      {
        code: 'IAB2-1',
        name: 'Auto Parts',
      },
      {
        code: 'IAB2-2',
        name: 'Auto Repair',
      },
      {
        code: 'IAB2-3',
        name: 'Buying/Selling Carsa',
      },
      {
        code: 'IAB2-4',
        name: 'Car Culture',
      },
      {
        code: 'IAB2-5',
        name: 'Certified Pre-Owned',
      },
      {
        code: 'IAB2-6',
        name: 'Convertible',
      },
      {
        code: 'IAB2-7',
        name: 'Coupe',
      },
      {
        code: 'IAB2-8',
        name: 'Crossover',
      },
      {
        code: 'IAB2-9',
        name: 'Diesel',
      },
      {
        code: 'IAB2-10',
        name: 'Electric Vehicle',
      },
      {
        code: 'IAB2-11',
        name: 'Hatchback',
      },
      {
        code: 'IAB2-12',
        name: 'Hybrid',
      },
      {
        code: 'IAB2-13',
        name: 'Luxury',
      },
      {
        code: 'IAB2-14',
        name: 'Minivan',
      },
      {
        code: 'IAB2-15',
        name: 'Motorcycles',
      },
      {
        code: 'IAB2-16',
        name: 'Off-Road Vehicles',
      },
      {
        code: 'IAB2-17',
        name: 'Performance Vehicles',
      },
      {
        code: 'IAB2-18',
        name: 'Pickup',
      },
      {
        code: 'IAB2-19',
        name: 'Road-Side Assistance',
      },
      {
        code: 'IAB2-20',
        name: 'Sedan',
      },
      {
        code: 'IAB2-21',
        name: 'Trucks & Accessories',
      },
      {
        code: 'IAB2-22',
        name: 'Vintage Cars',
      },
      {
        code: 'IAB2-23',
        name: 'Wagon',
      },
    ],
  },
  IAB3: {
    code: 'IAB3',
    name: 'Business',
    children: [
      {
        code: 'IAB3-1',
        name: 'Advertising',
      },
      {
        code: 'IAB3-2',
        name: 'Agriculture',
      },
      {
        code: 'IAB3-3',
        name: 'Biotech/Biomedical',
      },
      {
        code: 'IAB3-4',
        name: 'Business Software',
      },
      {
        code: 'IAB3-5',
        name: 'Construction',
      },
      {
        code: 'IAB3-6',
        name: 'Forestry',
      },
      {
        code: 'IAB3-7',
        name: 'Government',
      },
      {
        code: 'IAB3-8',
        name: 'Green Solutions',
      },
      {
        code: 'IAB3-9',
        name: 'Human Resources',
      },
      {
        code: 'IAB3-10',
        name: 'Logistics',
      },
      {
        code: 'IAB3-11',
        name: 'Marketing',
      },
      {
        code: 'IAB3-12',
        name: 'Metals',
      },
    ],
  },
  IAB4: {
    code: 'IAB4',
    name: 'Careers',
    children: [
      {
        code: 'IAB4-1',
        name: 'Career Planning',
      },
      {
        code: 'IAB4-2',
        name: 'College',
      },
      {
        code: 'IAB4-3',
        name: 'Financial Aid',
      },
      {
        code: 'IAB4-4',
        name: 'Job Fairs',
      },
      {
        code: 'IAB4-5',
        name: 'Job Search',
      },
      {
        code: 'IAB4-6',
        name: 'Resume Writing/Advice',
      },
      {
        code: 'IAB4-7',
        name: 'Nursing',
      },
      {
        code: 'IAB4-8',
        name: 'Scholarships',
      },
      {
        code: 'IAB4-9',
        name: 'Telecommuting',
      },
      {
        code: 'IAB4-10',
        name: 'U.S. Military',
      },
      {
        code: 'IAB4-11',
        name: 'Career Advice',
      },
    ],
  },
  IAB5: {
    code: 'IAB5',
    name: 'Education',
    children: [
      {
        code: 'IAB5-1',
        name: '7-12 Education',
      },
      {
        code: 'IAB5-2',
        name: 'Adult Education',
      },
      {
        code: 'IAB5-3',
        name: 'Art History',
      },
      {
        code: 'IAB5-4',
        name: 'College Administration',
      },
      {
        code: 'IAB5-5',
        name: 'College Life',
      },
      {
        code: 'IAB5-6',
        name: 'Distance Learning',
      },
      {
        code: 'IAB5-7',
        name: 'English as a 2nd Language',
      },
      {
        code: 'IAB5-8',
        name: 'Language Learning',
      },
      {
        code: 'IAB5-9',
        name: 'Graduate School',
      },
      {
        code: 'IAB5-10',
        name: 'Homeschooling',
      },
      {
        code: 'IAB5-11',
        name: 'Homework/Study Tips',
      },
      {
        code: 'IAB5-12',
        name: 'K-6 Educators',
      },
      {
        code: 'IAB5-13',
        name: 'Private School',
      },
      {
        code: 'IAB5-14',
        name: 'Special Education',
      },
      {
        code: 'IAB5-15',
        name: 'Studying Business',
      },
    ],
  },
  IAB6: {
    code: 'IAB6',
    name: 'Family & Parenting',
    children: [
      {
        code: 'IAB6-1',
        name: 'Adoption',
      },
      {
        code: 'IAB6-2',
        name: 'Babies & Toddlers',
      },
      {
        code: 'IAB6-3',
        name: 'Daycare/Pre School',
      },
      {
        code: 'IAB6-4',
        name: 'Family Internet',
      },
      {
        code: 'IAB6-5',
        name: 'Parenting - K-6 Kids',
      },
      {
        code: 'IAB6-6',
        name: 'Parenting teens',
      },
      {
        code: 'IAB6-7',
        name: 'Pregnancy',
      },
      {
        code: 'IAB6-8',
        name: 'Special Needs Kids',
      },
      {
        code: 'IAB6-9',
        name: 'Eldercare',
      },
    ],
  },
  IAB7: {
    code: 'IAB7',
    name: 'Health & Fitness',
    children: [
      {
        code: 'IAB7-1',
        name: 'Exercise',
      },
      {
        code: 'IAB7-2',
        name: 'ADD',
      },
      {
        code: 'IAB7-3',
        name: 'AIDS/HIV',
      },
      {
        code: 'IAB7-4',
        name: 'Allergies',
      },
      {
        code: 'IAB7-5',
        name: 'Alternative Medicine',
      },
      {
        code: 'IAB7-6',
        name: 'Arthritis',
      },
      {
        code: 'IAB7-7',
        name: 'Asthma',
      },
      {
        code: 'IAB7-8',
        name: 'Autism/PDD',
      },
      {
        code: 'IAB7-9',
        name: 'Bipolar Disorder',
      },
      {
        code: 'IAB7-10',
        name: 'Brain Tumor',
      },
      {
        code: 'IAB7-11',
        name: 'Cancer',
      },
      {
        code: 'IAB7-12',
        name: 'Cholesterol',
      },
      {
        code: 'IAB7-13',
        name: 'Chronic Fatigue Syndrome',
      },
      {
        code: 'IAB7-14',
        name: 'Chronic Pain',
      },
      {
        code: 'IAB7-15',
        name: 'Cold & Flu',
      },
      {
        code: 'IAB7-16',
        name: 'Deafness',
      },
      {
        code: 'IAB7-17',
        name: 'Dental Care',
      },
      {
        code: 'IAB7-18',
        name: 'Depression',
      },
      {
        code: 'IAB7-19',
        name: 'Dermatology',
      },
      {
        code: 'IAB7-20',
        name: 'Diabetes',
      },
      {
        code: 'IAB7-21',
        name: 'Epilepsy',
      },
      {
        code: 'IAB7-22',
        name: 'GERD/Acid Reflux',
      },
      {
        code: 'IAB7-23',
        name: 'Headaches/Migraines',
      },
      {
        code: 'IAB7-24',
        name: 'Heart Disease',
      },
      {
        code: 'IAB7-25',
        name: 'Herbs for Health',
      },
      {
        code: 'IAB7-26',
        name: 'Holistic Healing',
      },
      {
        code: 'IAB7-27',
        name: 'IBS/Crohn’s Disease',
      },
      {
        code: 'IAB7-28',
        name: 'Incest/Abuse Support',
      },
      {
        code: 'IAB7-29',
        name: 'Incontinence',
      },
      {
        code: 'IAB7-30',
        name: 'Infertility',
      },
      {
        code: 'IAB7-31',
        name: 'Men’s Health',
      },
      {
        code: 'IAB7-32',
        name: 'Nutrition',
      },
      {
        code: 'IAB7-33',
        name: 'Orthopedics',
      },
      {
        code: 'IAB7-34',
        name: 'Panic/Anxiety Disorders',
      },
      {
        code: 'IAB7-35',
        name: 'Pediatrics',
      },
      {
        code: 'IAB7-36',
        name: 'Physical Therapy',
      },
      {
        code: 'IAB7-37',
        name: 'Psychology/Psychiatry',
      },
      {
        code: 'IAB7-38',
        name: 'Senior Health',
      },
      {
        code: 'IAB7-39',
        name: 'Sexuality',
      },
      {
        code: 'IAB7-40',
        name: 'Sleep Disorders',
      },
      {
        code: 'IAB7-41',
        name: 'Smoking Cessation',
      },
      {
        code: 'IAB7-42',
        name: 'Substance Abuse',
      },
      {
        code: 'IAB7-43',
        name: 'Thyroid Disease',
      },
      {
        code: 'IAB7-44',
        name: 'Weight Loss',
      },
      {
        code: 'IAB7-45',
        name: 'Women\'s Health',
      },
    ],
  },
  IAB8: {
    code: 'IAB8',
    name: 'Food & Drink',
    children: [
      {
        code: 'IAB8-1',
        name: 'American Cuisine',
      },
      {
        code: 'IAB8-2',
        name: 'Barbecues & Grilling',
      },
      {
        code: 'IAB8-3',
        name: 'Cajun/Creole',
      },
      {
        code: 'IAB8-4',
        name: 'Chinese Cuisine',
      },
      {
        code: 'IAB8-5',
        name: 'Cocktails/Beer',
      },
      {
        code: 'IAB8-6',
        name: 'Coffee/Tea',
      },
      {
        code: 'IAB8-7',
        name: 'Cuisine-Specific',
      },
      {
        code: 'IAB8-8',
        name: 'Desserts & Baking',
      },
      {
        code: 'IAB8-9',
        name: 'Dining Out',
      },
      {
        code: 'IAB8-10',
        name: 'Food Allergies',
      },
      {
        code: 'IAB8-11',
        name: 'French Cuisine',
      },
      {
        code: 'IAB8-12',
        name: 'Health/Low-Fat Cooking',
      },
      {
        code: 'IAB8-13',
        name: 'Italian Cuisine',
      },
      {
        code: 'IAB8-14',
        name: 'Japanese Cuisine',
      },
      {
        code: 'IAB8-15',
        name: 'Mexican Cuisine',
      },
      {
        code: 'IAB8-16',
        name: 'Vegan',
      },
      {
        code: 'IAB8-17',
        name: 'Vegetarian',
      },
      {
        code: 'IAB8-18',
        name: 'Wine',
      },
    ],
  },
  IAB9: {
    code: 'IAB9',
    name: 'Hobbies & Interests',
    children: [
      {
        code: 'IAB9-1',
        name: 'Art/Technology',
      },
      {
        code: 'IAB9-2',
        name: 'Arts & Crafts',
      },
      {
        code: 'IAB9-3',
        name: 'Beadwork',
      },
      {
        code: 'IAB9-4',
        name: 'Bird-Watching',
      },
      {
        code: 'IAB9-5',
        name: 'Board Games/Puzzles',
      },
      {
        code: 'IAB9-6',
        name: 'Candle & Soap Making',
      },
      {
        code: 'IAB9-7',
        name: 'Card Games',
      },
      {
        code: 'IAB9-8',
        name: 'Chess',
      },
      {
        code: 'IAB9-9',
        name: 'Cigars',
      },
      {
        code: 'IAB9-10',
        name: 'Collecting',
      },
      {
        code: 'IAB9-11',
        name: 'Comic Books',
      },
      {
        code: 'IAB9-12',
        name: 'Drawing/Sketching',
      },
      {
        code: 'IAB9-13',
        name: 'Freelance Writing',
      },
      {
        code: 'IAB9-14',
        name: 'Genealogy',
      },
      {
        code: 'IAB9-15',
        name: 'Getting Published',
      },
      {
        code: 'IAB9-16',
        name: 'Guitar',
      },
      {
        code: 'IAB9-17',
        name: 'Home Recording',
      },
      {
        code: 'IAB9-18',
        name: 'Investors & Patents',
      },
      {
        code: 'IAB9-19',
        name: 'Jewelry Making',
      },
      {
        code: 'IAB9-20',
        name: 'Magic & Illusion',
      },
      {
        code: 'IAB9-21',
        name: 'Needlework',
      },
      {
        code: 'IAB9-22',
        name: 'Painting',
      },
      {
        code: 'IAB9-23',
        name: 'Photography',
      },
      {
        code: 'IAB9-24',
        name: 'Radio',
      },
      {
        code: 'IAB9-25',
        name: 'Roleplaying Games',
      },
      {
        code: 'IAB9-26',
        name: 'Sci-Fi & Fantasy',
      },
      {
        code: 'IAB9-27',
        name: 'Scrapbooking',
      },
      {
        code: 'IAB9-28',
        name: 'Screenwriting',
      },
      {
        code: 'IAB9-29',
        name: 'Stamps & Coins',
      },
      {
        code: 'IAB9-30',
        name: 'Video & Computer Games',
      },
      {
        code: 'IAB9-31',
        name: 'Woodworking',
      },
    ],
  },
  IAB10: {
    code: 'IAB10',
    name: 'Home & Garden',
    children: [
      {
        code: 'IAB10-1',
        name: 'Appliances',
      },
      {
        code: 'IAB10-2',
        name: 'Entertaining',
      },
      {
        code: 'IAB10-3',
        name: 'Environmental Safety',
      },
      {
        code: 'IAB10-4',
        name: 'Gardening',
      },
      {
        code: 'IAB10-5',
        name: 'Home Repair',
      },
      {
        code: 'IAB10-6',
        name: 'Home Theater',
      },
      {
        code: 'IAB10-7',
        name: 'Interior Decorating',
      },
      {
        code: 'IAB10-8',
        name: 'Landscaping',
      },
      {
        code: 'IAB10-9',
        name: 'Remodeling & Construction',
      },
    ],
  },
  IAB11: {
    code: 'IAB11',
    name: 'Law, Government & Politics',
    children: [
      {
        code: 'IAB11-1',
        name: 'Immigration',
      },
      {
        code: 'IAB11-2',
        name: 'Legal Issues',
      },
      {
        code: 'IAB11-3',
        name: 'U.S. Government Resources',
      },
      {
        code: 'IAB11-4',
        name: 'Politics',
      },
      {
        code: 'IAB11-5',
        name: 'Commentary',
      },
    ],
  },
  IAB12: {
    code: 'IAB12',
    name: 'News',
    children: [
      {
        code: 'IAB12-1',
        name: 'International News',
      },
      {
        code: 'IAB12-2',
        name: 'National News',
      },
      {
        code: 'IAB12-3',
        name: 'Local News',
      },
    ],
  },
  IAB13: {
    code: 'IAB13',
    name: 'Personal Finance',
    children: [
      {
        code: 'IAB13-1',
        name: 'Beginning Investing',
      },
      {
        code: 'IAB13-2',
        name: 'Credit/Debt & Loans',
      },
      {
        code: 'IAB13-3',
        name: 'Financial News',
      },
      {
        code: 'IAB13-4',
        name: 'Financial Planning',
      },
      {
        code: 'IAB13-5',
        name: 'Hedge Fund',
      },
      {
        code: 'IAB13-6',
        name: 'Insurance',
      },
      {
        code: 'IAB13-7',
        name: 'Investing',
      },
      {
        code: 'IAB13-8',
        name: 'Mutual Funds',
      },
      {
        code: 'IAB13-9',
        name: 'Options',
      },
      {
        code: 'IAB13-10',
        name: 'Retirement Planning',
      },
      {
        code: 'IAB13-11',
        name: 'Stocks',
      },
      {
        code: 'IAB13-12',
        name: 'Tax Planning',
      },
    ],
  },
  IAB14: {
    code: 'IAB14',
    name: 'Society',
    children: [
      {
        code: 'IAB14-1',
        name: 'Dating',
      },
      {
        code: 'IAB14-2',
        name: 'Divorce Support',
      },
      {
        code: 'IAB14-3',
        name: 'Gay Life',
      },
      {
        code: 'IAB14-4',
        name: 'Marriage',
      },
      {
        code: 'IAB14-5',
        name: 'Senior Living',
      },
      {
        code: 'IAB14-6',
        name: 'Teens',
      },
      {
        code: 'IAB14-7',
        name: 'Weddings',
      },
      {
        code: 'IAB14-8',
        name: 'Ethnic Specific',
      },
    ],
  },
  IAB15: {
    code: 'IAB15',
    name: 'Science',
    children: [
      {
        code: 'IAB15-1',
        name: 'Astrology',
      },
      {
        code: 'IAB15-2',
        name: 'Biology',
      },
      {
        code: 'IAB15-3',
        name: 'Chemistry',
      },
      {
        code: 'IAB15-4',
        name: 'Geology',
      },
      {
        code: 'IAB15-5',
        name: 'Paranormal Phenomena',
      },
      {
        code: 'IAB15-6',
        name: 'Physics',
      },
      {
        code: 'IAB15-7',
        name: 'Space/Astronomy',
      },
      {
        code: 'IAB15-8',
        name: 'Geography',
      },
      {
        code: 'IAB15-9',
        name: 'Botany',
      },
      {
        code: 'IAB15-10',
        name: 'Weather',
      },
    ],
  },
  IAB16: {
    code: 'IAB16',
    name: 'Pets',
    children: [
      {
        code: 'IAB16-1',
        name: 'Aquariums',
      },
      {
        code: 'IAB16-2',
        name: 'Birds',
      },
      {
        code: 'IAB16-3',
        name: 'Cats',
      },
      {
        code: 'IAB16-4',
        name: 'Dogs',
      },
      {
        code: 'IAB16-5',
        name: 'Large Animals',
      },
      {
        code: 'IAB16-6',
        name: 'Reptiles',
      },
      {
        code: 'IAB16-7',
        name: 'Veterinary Medicine',
      },
    ],
  },
  IAB17: {
    code: 'IAB17',
    name: 'Sports',
    children: [
      {
        code: 'IAB17-1',
        name: 'Auto Racing',
      },
      {
        code: 'IAB17-2',
        name: 'Baseball',
      },
      {
        code: 'IAB17-3',
        name: 'Bicycling',
      },
      {
        code: 'IAB17-4',
        name: 'Bodybuilding',
      },
      {
        code: 'IAB17-5',
        name: 'Boxing',
      },
      {
        code: 'IAB17-6',
        name: 'Canoeing/Kayaking',
      },
      {
        code: 'IAB17-7',
        name: 'Cheerleading',
      },
      {
        code: 'IAB17-8',
        name: 'Climbing',
      },
      {
        code: 'IAB17-9',
        name: 'Cricket',
      },
      {
        code: 'IAB17-10',
        name: 'Figure Skating',
      },
      {
        code: 'IAB17-11',
        name: 'Fly Fishing',
      },
      {
        code: 'IAB17-12',
        name: 'Football',
      },
      {
        code: 'IAB17-13',
        name: 'Freshwater Fishing',
      },
      {
        code: 'IAB17-14',
        name: 'Game & Fish',
      },
      {
        code: 'IAB17-15',
        name: 'Golf',
      },
      {
        code: 'IAB17-16',
        name: 'Horse Racing',
      },
      {
        code: 'IAB17-17',
        name: 'Horses',
      },
      {
        code: 'IAB17-18',
        name: 'Hunting/Shooting',
      },
      {
        code: 'IAB17-19',
        name: 'Inline Skating',
      },
      {
        code: 'IAB17-20',
        name: 'Martial Arts',
      },
      {
        code: 'IAB17-21',
        name: 'Mountain Biking',
      },
      {
        code: 'IAB17-22',
        name: 'NASCAR Racing',
      },
      {
        code: 'IAB17-23',
        name: 'Olympics',
      },
      {
        code: 'IAB17-24',
        name: 'Paintball',
      },
      {
        code: 'IAB17-25',
        name: 'Power & Motorcycles',
      },
      {
        code: 'IAB17-26',
        name: 'Pro Basketball',
      },
      {
        code: 'IAB17-27',
        name: 'Pro Ice Hockey',
      },
      {
        code: 'IAB17-28',
        name: 'Rodeo',
      },
      {
        code: 'IAB17-29',
        name: 'Rugby',
      },
      {
        code: 'IAB17-30',
        name: 'Running/Jogging',
      },
      {
        code: 'IAB17-31',
        name: 'Sailing',
      },
      {
        code: 'IAB17-32',
        name: 'Saltwater Fishing',
      },
      {
        code: 'IAB17-33',
        name: 'Scuba Diving',
      },
      {
        code: 'IAB17-34',
        name: 'Skateboarding',
      },
      {
        code: 'IAB17-35',
        name: 'Skiing',
      },
      {
        code: 'IAB17-36',
        name: 'Snowboarding',
      },
      {
        code: 'IAB17-37',
        name: 'Surfing/Body-Boarding',
      },
      {
        code: 'IAB17-38',
        name: 'Swimming',
      },
      {
        code: 'IAB17-39',
        name: 'Table Tennis/Ping-Pong',
      },
      {
        code: 'IAB17-40',
        name: 'Tennis',
      },
      {
        code: 'IAB17-41',
        name: 'Volleyball',
      },
      {
        code: 'IAB17-42',
        name: 'Walking',
      },
      {
        code: 'IAB17-43',
        name: 'Waterski/Wakeboard',
      },
      {
        code: 'IAB17-44',
        name: 'World Soccer',
      },
    ],
  },
  IAB18: {
    code: 'IAB18',
    name: 'Style & Fashion',
    children: [
      {
        code: 'IAB18-1',
        name: 'Beauty',
      },
      {
        code: 'IAB18-2',
        name: 'Body Art',
      },
      {
        code: 'IAB18-3',
        name: 'Fashion',
      },
      {
        code: 'IAB18-4',
        name: 'Jewelry',
      },
      {
        code: 'IAB18-5',
        name: 'Clothing',
      },
      {
        code: 'IAB18-6',
        name: 'Accessories',
      },
    ],
  },
  IAB19: {
    code: 'IAB19',
    name: 'Technology & Computing',
    children: [
      {
        code: 'IAB19-1',
        name: '3-D Graphics',
      },
      {
        code: 'IAB19-2',
        name: 'Animation',
      },
      {
        code: 'IAB19-3',
        name: 'Antivirus Software',
      },
      {
        code: 'IAB19-4',
        name: 'C/C++',
      },
      {
        code: 'IAB19-5',
        name: 'Cameras & Camcorders',
      },
      {
        code: 'IAB19-6',
        name: 'Cell Phones',
      },
      {
        code: 'IAB19-7',
        name: 'Computer Certification',
      },
      {
        code: 'IAB19-8',
        name: 'Computer Networking',
      },
      {
        code: 'IAB19-9',
        name: 'Computer Peripherals',
      },
      {
        code: 'IAB19-10',
        name: 'Computer Reviews',
      },
      {
        code: 'IAB19-11',
        name: 'Data Centers',
      },
      {
        code: 'IAB19-12',
        name: 'Databases',
      },
      {
        code: 'IAB19-13',
        name: 'Desktop Publishing',
      },
      {
        code: 'IAB19-14',
        name: 'Desktop Video',
      },
      {
        code: 'IAB19-15',
        name: 'Email',
      },
      {
        code: 'IAB19-16',
        name: 'Graphics Software',
      },
      {
        code: 'IAB19-17',
        name: 'Home Video/DVD',
      },
      {
        code: 'IAB19-18',
        name: 'Internet Technology',
      },
      {
        code: 'IAB19-19',
        name: 'Java',
      },
      {
        code: 'IAB19-20',
        name: 'JavaScript',
      },
      {
        code: 'IAB19-21',
        name: 'Mac Support',
      },
      {
        code: 'IAB19-22',
        name: 'MP3/MIDI',
      },
      {
        code: 'IAB19-23',
        name: 'Net Conferencing',
      },
      {
        code: 'IAB19-24',
        name: 'Net for Beginners',
      },
      {
        code: 'IAB19-25',
        name: 'Network Security',
      },
      {
        code: 'IAB19-26',
        name: 'Palmtops/PDAs',
      },
      {
        code: 'IAB19-27',
        name: 'PC Support',
      },
      {
        code: 'IAB19-28',
        name: 'Portable',
      },
      {
        code: 'IAB19-29',
        name: 'Entertainment',
      },
      {
        code: 'IAB19-30',
        name: 'Shareware/Freeware',
      },
      {
        code: 'IAB19-31',
        name: 'Unix',
      },
      {
        code: 'IAB19-32',
        name: 'Visual Basic',
      },
      {
        code: 'IAB19-33',
        name: 'Web Clip Art',
      },
      {
        code: 'IAB19-34',
        name: 'Web Design/HTML',
      },
      {
        code: 'IAB19-35',
        name: 'Web Search',
      },
      {
        code: 'IAB19-36',
        name: 'Windows',
      },
    ],
  },
  IAB20: {
    code: 'IAB20',
    name: 'Travel',
    children: [
      {
        code: 'IAB20-1',
        name: 'Adventure Travel',
      },
      {
        code: 'IAB20-2',
        name: 'Africa',
      },
      {
        code: 'IAB20-3',
        name: 'Air Travel',
      },
      {
        code: 'IAB20-4',
        name: 'Australia & New Zealand',
      },
      {
        code: 'IAB20-5',
        name: 'Bed & Breakfasts',
      },
      {
        code: 'IAB20-6',
        name: 'Budget Travel',
      },
      {
        code: 'IAB20-7',
        name: 'Business Travel',
      },
      {
        code: 'IAB20-8',
        name: 'By US Locale',
      },
      {
        code: 'IAB20-9',
        name: 'Camping',
      },
      {
        code: 'IAB20-10',
        name: 'Canada',
      },
      {
        code: 'IAB20-11',
        name: 'Caribbean',
      },
      {
        code: 'IAB20-12',
        name: 'Cruises',
      },
      {
        code: 'IAB20-13',
        name: 'Eastern Europe',
      },
      {
        code: 'IAB20-14',
        name: 'Europe',
      },
      {
        code: 'IAB20-15',
        name: 'France',
      },
      {
        code: 'IAB20-16',
        name: 'Greece',
      },
      {
        code: 'IAB20-17',
        name: 'Honeymoons/Getaways',
      },
      {
        code: 'IAB20-18',
        name: 'Hotels',
      },
      {
        code: 'IAB20-19',
        name: 'Italy',
      },
      {
        code: 'IAB20-20',
        name: 'Japan',
      },
      {
        code: 'IAB20-21',
        name: 'Mexico & Central America',
      },
      {
        code: 'IAB20-22',
        name: 'National Parks',
      },
      {
        code: 'IAB20-23',
        name: 'South America',
      },
      {
        code: 'IAB20-24',
        name: 'Spas',
      },
      {
        code: 'IAB20-25',
        name: 'Theme Parks',
      },
      {
        code: 'IAB20-26',
        name: 'Traveling with Kids',
      },
      {
        code: 'IAB20-27',
        name: 'United Kingdom',
      },
    ],
  },
  IAB21: {
    code: 'IAB21',
    name: 'Real Estate',
    children: [
      {
        code: 'IAB21-1',
        name: 'Apartments',
      },
      {
        code: 'IAB21-2',
        name: 'Architects',
      },
      {
        code: 'IAB21-3',
        name: 'Buying/Selling Homes',
      },
    ],
  },
  IAB22: {
    code: 'IAB22',
    name: 'Shopping',
    children: [
      {
        code: 'IAB22-1',
        name: 'Contests & Freebies',
      },
      {
        code: 'IAB22-2',
        name: 'Couponing',
      },
      {
        code: 'IAB22-3',
        name: 'Comparison',
      },
      {
        code: 'IAB22-4',
        name: 'Engines',
      },
    ],
  },
  IAB23: {
    code: 'IAB23',
    name: 'Religion & Spirituality',
    children: [
      {
        code: 'IAB23-1',
        name: 'Alternative Religions',
      },
      {
        code: 'IAB23-2',
        name: 'Atheism/Agnosticism',
      },
      {
        code: 'IAB23-3',
        name: 'Buddhism',
      },
      {
        code: 'IAB23-4',
        name: 'Catholicism',
      },
      {
        code: 'IAB23-5',
        name: 'Christianity',
      },
      {
        code: 'IAB23-6',
        name: 'Hinduism',
      },
      {
        code: 'IAB23-7',
        name: 'Islam',
      },
      {
        code: 'IAB23-8',
        name: 'Judaism',
      },
      {
        code: 'IAB23-9',
        name: 'Latter-Day Saints',
      },
      {
        code: 'IAB23-10',
        name: 'Pagan/Wiccan',
      },
    ],
  },
}

export default iabs
