import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { Popup, Label } from 'semantic-ui-react'
import moment from 'moment-timezone'
import { DayPickerRangeController } from 'react-dates'
import { momentObj } from 'react-moment-proptypes'

import { formatDateToLocale } from '../../utils/time'
import styles from './semantic-date-picker.css'


const propTypes = {
  endDate: momentObj.isRequired,
  startDate: momentObj.isRequired,
  onSelection: PropTypes.func.isRequired,
  blockedEnd: momentObj,
  blockedStart: momentObj,
  endDateOnly: PropTypes.bool,
  labelProps: PropTypes.object,
  numberOfMonths: PropTypes.number,
  position: PropTypes.string,
  keepOpenOnSelection: PropTypes.bool,
  keepDatesOnOpen: PropTypes.bool,
  maximumNights: PropTypes.number,
}

const defaultProps = {
  blockedEnd: null,
  blockedStart: null,
  endDateOnly: false,
  labelProps: {},
  numberOfMonths: 1,
  position: null,
  keepOpenOnSelection: false,
  keepDatesOnOpen: false,
  maximumNights: 0,
}

class SemanticDatePicker extends Component {
  constructor(props) {
    super(props)

    this.state = {
      focusedInput: null,
      isOpen: false,
      // TODO: use of supposedly same values from props
      // cause weird rendering of highlighted days
      startDate: moment(props.startDate),
      endDate: moment(props.endDate),
    }
  }

  open = () => {
    this.setState({ isOpen: true })
    const { keepDatesOnOpen, endDateOnly } = this.props

    if (!keepDatesOnOpen) {
      this.setState({ endDate: null })
      if (!endDateOnly) {
        this.setState({ startDate: null })
      }
    }
  }

  close = () => {
    const { startDate, endDate } = this.props
    this.setState({
      isOpen: false,
      startDate: moment(startDate),
      endDate: moment(endDate),
    })
  }

  setFocusedInput() {
    const { endDateOnly } = this.props
    this.setState({ focusedInput: endDateOnly ? 'endDate' : 'startDate' })
  }

  onFocusChange = (focus) => {
    if (focus === null) {
      this.setFocusedInput()
    } else {
      this.setState({ focusedInput: focus })
    }
  }

  onOpen = () => {
    this.setFocusedInput()
  }

  renderTrigger() {
    const { labelProps } = this.props
    const { startDate, endDate } = this.state

    const startDateString = formatDateToLocale(startDate)
    const endDateString = endDate ? formatDateToLocale(endDate) : 'Select Date'

    const durationString = startDate
      ? `${(startDateString)} \u2192 ${endDateString}`
      : 'Select a Date Range'

    return (
      <Label
        basic
        content={durationString}
        {...labelProps}
        className={`${styles.label} ${labelProps.className}`}
        onClick={this.open}
      />
    )
  }

  onDatesChange = ({ startDate, endDate }) => {
    const { endDate: _endDate } = this.state
    const { keepOpenOnSelection, onSelection } = this.props
    const endStart = moment(endDate).startOf('day')
    const currEndStart = moment(_endDate).startOf('day')
    if (!(endDate === null || endStart.isSame(currEndStart))) {
      if (!keepOpenOnSelection) {
        this.close()
      }
      onSelection({ startDate, endDate })
    }

    this.setState({ startDate, endDate })
  }

  isDayHighlighted = (day) => {
    const { startDate, endDate } = this.props
    return day.isBetween(
      moment(startDate).startOf('day'),
      moment(endDate).endOf('day'),
      null,
      '[]', // inclusive
    )
  }

  isOutsideRange = (day) => {
    const {
      focusedInput,
      // use the local state version since this is dynamic based
      // on current selections, like the renderTrigger() method
      startDate,
    } = this.state
    const {
      endDateOnly,
      blockedEnd,
      blockedStart,
      maximumNights,
    } = this.props
    const dayStart = moment(day).startOf('day')
    const startStart = moment(startDate).startOf('day')
    let blocked = false
    if (endDateOnly) {
      blocked = blocked || dayStart.isBefore(startStart)
    }

    if (moment(blockedStart).isValid()) {
      blocked = blocked || dayStart.isBefore(moment(blockedStart).startOf('day'))
    }

    if (moment(blockedEnd).isValid()) {
      blocked = blocked || dayStart.isAfter(moment(blockedEnd).startOf('day'))
    }

    if (maximumNights > 0 && moment(startDate).isValid() && focusedInput === 'endDate') {
      const maxLimit = moment(startStart).add(maximumNights, 'days')
      blocked = blocked || dayStart.isSameOrAfter(maxLimit)
    }

    return blocked
  }

  initialVisibleMonth = () => {
    const { numberOfMonths, blockedEnd, endDate } = this.props
    let initialMonth = moment()

    if (moment(endDate).isValid()) {
      initialMonth = moment(endDate)
    } else if (moment(blockedEnd).isValid()) {
      initialMonth = moment(blockedEnd)
    }

    if (numberOfMonths > 1) {
      initialMonth.subtract(1, 'month')
    }

    return initialMonth
  }

  renderDatePicker() {
    const { focusedInput, startDate, endDate } = this.state
    const { numberOfMonths } = this.props

    return (
      <div className='semanticCalendar'>
        <DayPickerRangeController
          startDate={startDate}
          endDate={endDate}
          onDatesChange={this.onDatesChange}
          focusedInput={focusedInput}
          onFocusChange={this.onFocusChange}
          numberOfMonths={numberOfMonths}
          isOutsideRange={this.isOutsideRange}
          isDayHighlighted={this.isDayHighlighted}
          hideKeyboardShortcutsPanel
          initialVisibleMonth={this.initialVisibleMonth}
          minimumNights={0}
        />
      </div>
    )
  }

  render() {
    const { position } = this.props
    const { isOpen } = this.state

    return (
      <Popup
        basic
        trigger={this.renderTrigger()}
        content={this.renderDatePicker()}
        on='click'
        onOpen={this.onOpen}
        onClose={this.close}
        open={isOpen}
        position={position}
      />
    )
  }
}

SemanticDatePicker.propTypes = propTypes
SemanticDatePicker.defaultProps = defaultProps

export default SemanticDatePicker
