import React from 'react'
import PropTypes from 'prop-types'

import { Input, Form, Grid, Dropdown } from 'semantic-ui-react'
import { forOwn } from 'lodash'

import InlineFormField from '../../../../common-components/inline-form-field'
import AttachedLabels from '../../../../common-components/attached-labels'
import DurationField from '../common-edit-fields/duration-field'
import { DEFAULT_TZ } from '../../../../utils/time'
import iabCats from '../../../../utils/iab-cat'
import styles from './summary-edit.css'


const propTypes = {
  OfferCategories: PropTypes.string,
  Name: PropTypes.string,
  StartDate: PropTypes.string,
  EndDate: PropTypes.string,
  Draft: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  dateHandler: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
}

const defaultProps = {
  OfferCategories: null,
  Name: '',
  StartDate: '',
  EndDate: '',
  Draft: false,
}

const CampaignSummaryEdit = ({
  OfferCategories,
  Name,
  StartDate,
  EndDate,
  Draft,
  onChange,
  dateHandler,
  errors,
}) => {
  const NameField = (
    <InlineFormField errorText={errors.Name} label='Name' width={16}>
      <Input
        value={Name}
        onChange={onChange('Name')}
      />
    </InlineFormField>
  )

  let dropdownText = 'None'
  const iabOptions = [{
    key: dropdownText,
    value: null,
    text: dropdownText,
  }]

  forOwn(iabCats, (iab) => {
    const iabOption = {
      key: iab.code,
      value: iab.code,
      text: iab.name,
    }

    if (iab.code === OfferCategories) {
      dropdownText = iab.name
    }

    iabOptions.push(iabOption)
  })

  const infoText = dropdownText === 'None' ? 'Some exchanges require a category to be set' : ''

  const offerCategoriesField = (
    <InlineFormField errorText={errors.Category} label='Category' infoText={infoText} width={16}>
      <Dropdown
        search
        selection
        options={iabOptions}
        value={OfferCategories}
        text={dropdownText}
        fluid
        onChange={onChange('OfferCategories')}
      />
    </InlineFormField>
  )

  const durationField = (
    <DurationField
      StartDate={StartDate}
      EndDate={EndDate}
      Draft={Draft}
      errorText={errors.Duration}
      dateHandler={dateHandler}
    />
  )

  /* Use Label until we can make use of timezone
  const defaultTimeZone = this.props.TimeZone
  const options = timezones.map(tz => ({ key: tz, text: tz.replace('_', ' '), value: tz }))

  const setTimeZone = (e, { value }) => {
    this.updateMetadata({ TimeZone: value })
  }

  return (
    <LabeledSelection
      label={{ content: 'Time Zone', icon: 'world' }}
      options={options}
      search
      selection
      defaultValue={defaultTimeZone}
      onChange={setTimeZone}
    />
  )
  */

  const leftLabel = { icon: 'world', content: 'Time Zone', className: styles.timezoneLeft }
  const rightLabel = { content: DEFAULT_TZ, basic: true, className: styles.timezoneRight }

  const timezoneField = (
    <Form.Field>
      <AttachedLabels rightLabel={rightLabel} leftLabel={leftLabel} />
    </Form.Field>
  )

  return (
    <Form>
      <Grid padded>
        <Grid.Row>
          <Grid.Column>
            {NameField}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            {offerCategoriesField}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Form.Group style={{ margin: '0' }}>
              {durationField}
              {timezoneField}
            </Form.Group>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Form>
  )
}

CampaignSummaryEdit.propTypes = propTypes

CampaignSummaryEdit.defaultProps = defaultProps

export default CampaignSummaryEdit
