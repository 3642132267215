import { combineReducers } from 'redux'
import campaignReducer from './campaign/reducer'
import ancestorsReducer from './logged-in-container/reducer'
import indexReducer from './search/reducer'
import authReducer from './login/reducer'
import auditReducer from './audit/reducer'


export default combineReducers({
  campaign: campaignReducer,
  ancestors: ancestorsReducer,
  index: indexReducer,
  audit: auditReducer,
  authStatus: authReducer,
})
