import React from 'react'

import { Sparklines, SparklinesCurve, SparklinesReferenceLine } from 'react-sparklines'
import blue from '@material-ui/core/colors/blue'
import numeral from 'numeral'


export const renderTrend = ({
  key,
  format = '0,0a',
  trendClass,
// eslint-disable-next-line react/prop-types, react/display-name
}) => ({ value, row: { original: { delivery } } }) => {
  const trend = [...delivery]
  trend.sort((a, b) => new Date(a.Date) - new Date(b.Date))
  return (
    <>
      <strong>{numeral(value).format(format)}</strong>
      <span className={trendClass}>
        <Sparklines data={trend.map((d) => d[key])}>
          <SparklinesCurve color={blue[800]} style={{ fill: 'none' }} />
          <SparklinesReferenceLine type='mean' />
        </Sparklines>
      </span>
    </>
  )
}
