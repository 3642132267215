import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { debounce, remove, clone, sortBy } from 'lodash'
import { toast } from 'react-toastify'

import DomainPicker from './domain-picker'
import { overseer } from '../../utils/api'


const propTypes = {
  addDomain: PropTypes.func.isRequired,
  removeDomain: PropTypes.func.isRequired,
  domains: PropTypes.arrayOf(PropTypes.object).isRequired,
  domainListID: PropTypes.number.isRequired,
}

class DomainPickerContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      searchedDomains: [],
      loadingDomains: false,
      filterText: '',
      searchText: '',
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { domainListID } = this.props
    if (nextProps.domainListID !== domainListID) {
      this.setState({
        filterText: '',
        searchText: '',
        searchedDomains: [],
      })
    }
  }

  onChange = (field) => (_, { value }) => {
    this.setState({ [field]: value })

    if (field === 'searchText') {
      this.searchDomains(value)
    }
  }

  searchDomains = debounce(async (searchText) => {
    const { domains } = this.props
    if (searchText === '') {
      this.setState({ searchedDomains: [] })
      return
    }
    this.setState({ loadingDomains: true })

    try {
      const res = await overseer({
        url: `/search/domains?domain=${searchText}`,
        method: 'get',
      })
      const domainIds = domains.map((d) => d.DomainID)
      const searchedDomains = res.data.filter((d) => domainIds.indexOf(d.DomainID) === -1)

      this.setState({ loadingDomains: false, searchedDomains })
    } catch (err) {
      toast.error('Unable to search for domains')
      this.setState({ loadingDomains: false })
    }
  }, 1000)

  addDomainWrapper = (domain) => () => {
    const { addDomain } = this.props
    const { searchedDomains } = this.state
    const newSearchedDomains = clone(searchedDomains)
    remove(newSearchedDomains, (d) => d.DomainID === domain.DomainID)
    this.setState({ searchedDomains: newSearchedDomains })
    addDomain(domain)
  }

  removeDomainWrapper = (domain) => () => {
    const { searchedDomains, searchText } = this.state
    const { removeDomain } = this.props

    if (domain.TLD.includes(searchText) && searchText !== '') {
      const newSearchedDomains = clone(searchedDomains)
      newSearchedDomains.push(domain)
      this.setState({ searchedDomains: newSearchedDomains })
    }

    removeDomain(domain)
  }

  render() {
    const {
      searchedDomains,
      loadingDomains,
      filterText,
      searchText,
    } = this.state
    const { domains } = this.props

    const filteredDomains = domains.filter(({ TLD }) => TLD.includes(filterText))

    const sortedFilteredDomains = sortBy(filteredDomains.slice(), (d) => d.TLD)
    const sortedSearchedDomains = sortBy(searchedDomains.slice(), (d) => d.TLD)

    return (
      <DomainPicker
        loadingDomains={loadingDomains}
        searchDomains={this.searchDomains}
        searchedDomains={sortedSearchedDomains}
        addDomain={this.addDomainWrapper}
        onChange={this.onChange}
        filterText={filterText}
        searchText={searchText}
        filteredDomains={sortedFilteredDomains}
        removeDomain={this.removeDomainWrapper}
      />
    )
  }
}

DomainPickerContainer.propTypes = propTypes

export default DomainPickerContainer
