import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import { useAlgolia, OmniSearch } from '@eqworks/omni-search'
import { loadIndex } from './actions'
import { searchIndexLevelToRouteMap } from '../utils/level-to-name'
import { overseer } from '../utils/api'


const Search = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [searchAPIkey, setSearchAPIkey] = useState(null)
  useEffect(() => {
    const loadAlgoliaSearchkey = async () => {
      try {
        const response = await overseer({
          url: '/search/apikey',
          method: 'get',
        })
        setSearchAPIkey(response.data)
      } catch (error) {
        console.error(error)
      }
    }
    loadAlgoliaSearchkey()
  }, [searchAPIkey])

  useEffect(() => {
    const loadSearch = async () => {
      try {
        const response = await overseer({
          url: '/search',
          method: 'get',
        })
        dispatch(loadIndex(response.data))
      } catch (error) {
        console.error(error)
      }
    }
    loadSearch()
  }, [dispatch])

  const specs = {
    hitsPerPage: 50,
    getRankingInfo: true,
    restrictSearchableAttributes: ['name', 'id'],
    exactOnSingleWordQuery: 'word',
  }

  const { debouncedSearch, results } = useAlgolia({
    searchAPIkey,
    specs,
  })

  const select = (searchTerm) => {
    const newRoute = `/${searchIndexLevelToRouteMap[searchTerm.level]}/${searchTerm.id}`
    history.push(newRoute)
  }

  return (
    <>
      {searchAPIkey && (
        <OmniSearch {...{
          debouncedSearch,
          results,
          getSelection: select,
        }}
        />
      )}
    </>
  )
}

export default Search
