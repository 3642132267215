/* eslint-disable camelcase */
import React from 'react'
import PropTypes from 'prop-types'

import { Form, Modal, Dimmer, Loader } from 'semantic-ui-react'
import ConfirmCancelButtons from '../../common-components/confirm-cancel-buttons'
import DomainPickerContainer from './domain-picker-container'
import CSVImportModalContainer from './csv-import-modal-container'


const propTypes = {
  onInputChange: PropTypes.func.isRequired,
  createEditList: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  modalOpen: PropTypes.bool.isRequired,
  addDomain: PropTypes.func.isRequired,
  removeDomain: PropTypes.func.isRequired,
  trigger: PropTypes.node.isRequired,
  loadingCreate: PropTypes.bool.isRequired,
  edit: PropTypes.bool.isRequired,
  list_name: PropTypes.string.isRequired,
  domains: PropTypes.arrayOf(PropTypes.object).isRequired,
  domainListID: PropTypes.number.isRequired,
}

const CreateEditDomainList = ({
  onInputChange,
  domains,
  domainListID,
  createEditList,
  closeModal,
  modalOpen,
  addDomain,
  removeDomain,
  trigger,
  loadingCreate,
  edit,
  list_name,
}) => (
  <Modal
    trigger={trigger}
    open={modalOpen}
    onClose={closeModal}
    closeOnDimmerClick={false}
  >
    <Modal.Header content={`${edit ? 'Edit' : 'Create'} Domain List`} />
    <Modal.Content>
      <Dimmer inverted active={loadingCreate}>
        <Loader content='Loading' />
      </Dimmer>
      <Form>
        <Form.Input
          label='List Name:'
          onChange={onInputChange('list_name')}
          value={list_name}
          placeholder='Insert List Name...'
        />
      </Form>
      <DomainPickerContainer
        addDomain={addDomain}
        removeDomain={removeDomain}
        domains={domains}
        domainListID={domainListID}
      />
      <div>
        <CSVImportModalContainer
          addDomains={addDomain}
        />
        <ConfirmCancelButtons
          confirmClick={createEditList}
          cancelClick={closeModal}
          confirmContent='Save'
          floated='right'
          disabled={domains.length === 0 || list_name.trim() === ''}
        />
      </div>
    </Modal.Content>
  </Modal>
)

CreateEditDomainList.propTypes = propTypes

export default CreateEditDomainList
