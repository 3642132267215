export default function getAccess() {
  let access = window.localStorage.auth_access
  try {
    if (access) {
      access = JSON.parse(access)
    } else {
      access = {}
    }
  } catch (error) {
    access = {}
    console.error(error)
  }
  return access
}
