import { combineReducers } from 'redux'


export function isLoading(state = false, action) {
  switch (action.type) {
    case 'CHILDREN_TABLE_IS_LOADING':
      return action.isLoading
    default:
      return state
  }
}

export function data(state = [], action) {
  switch (action.type) {
    case 'POPULATE_CHILDREN':
      return action.payload
    default:
      return state
  }
}

const childrenReducer = combineReducers({
  data,
  isLoading,
})

export default childrenReducer
