import axios from 'axios'

const overseer = axios.create({
  baseURL: process.env.REACT_APP_OVERSEER_HOST === 'LOCAL'
    ? process.env.REACT_APP_API_HOST_LOCAL
    : `${process.env.REACT_APP_API_HOST}/${process.env.REACT_APP_API_VER || ''}`,
  timeout: 30000,
})

const firstorder = axios.create({
  baseURL: `${process.env.FO_HOST}/${process.env.FO_STAGE || ''}`,
  headers: { 'X-EQ-Product': 'atom' },
  timeout: 30000,
})

const keywarden = axios.create({
  baseURL: `${process.env.REACT_APP_KEY_WARDEN_HOST}/${process.env.REACT_APP_KEY_WARDEN_STAGE}`,
  timeout: 30000,
})

// local var for freshest jwt
let _jwt

// exported setter for invalidating the previous freshest jwt
export const resetJWT = () => {
  _jwt = undefined
}

// local getter for getting the freshest jwt
const getJWT = () => {
  if (!_jwt) {
    _jwt = window.localStorage.getItem('auth_jwt')
  }
  return _jwt
}

// for each defined api
for (const api of [overseer, firstorder, keywarden]) {
  // dynamically attach freshest jwt to each request 
  api.interceptors.request.use(config => {
    config.headers['eq-api-jwt'] = getJWT()
    return config
  })
}

// overseer auto-logout on overseer response AWS AccessDeniedException (remote) or 'Authentication failed' (local)
overseer.interceptors.response.use(null, (err) => {
  if (err.response) {
    if ([401, 403].includes(err.response.status)) {
      const { pathname, search, hash } = window.top.location
      const searchParams = new URLSearchParams(search.slice(1))
      window.localStorage.removeItem('auth_access')
      window.localStorage.removeItem('auth_jwt')
      if (!searchParams.has('redirect')) {
        window.location.replace(`/login?origin=${encodeURIComponent(pathname + search + hash)}&redirect`)
      }
    } else {
      return Promise.reject(err)
    }
  }
})

export { overseer, firstorder, keywarden }
