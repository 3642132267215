// from https://stackoverflow.com/a/5723274/5315062
import numeral from 'numeral'


export function truncate(fullStr, strLen, separator = ' ... ') {
  if (fullStr.length <= strLen) {
    return fullStr
  }
  const sepLen = separator.length
  const charsToShow = strLen - sepLen
  const frontChars = Math.ceil(charsToShow / 2)
  const backChars = Math.floor(charsToShow / 2)

  return fullStr.substr(0, frontChars)
           + separator
           + fullStr.substr(fullStr.length - backChars)
}


export function number(value, format = '0a') {
  // handle numeral's currency bug when value is a negative number
  if (format.charAt(0) === '$' && value < 0) {
    return `-$${Math.abs((value || 0)).toFixed(2)}`
  }

  return numeral(value).format(format)
}

export function toTitleCase(str) {
  // eslint-disable-next-line arrow-body-style
  return Object.entries(str).reduce((title, [iStr, char]) => {
    // eslint-disable-next-line radix
    const i = parseInt(iStr, 10)
    return title + (
      i === 0 || [' ', '.', '_', '-', '+'].includes(str[i - 1])
        ? char.toUpperCase()
        : char)
  }, '')
}

export function snakeToTitleCase(str) {
  return toTitleCase(str.replace(/_/g, ' '))
}

export function stripLastInstance(str, seq, mustTrail = false) {
  if (mustTrail) {
    return str.endsWith(seq) ? str.slice(0, - seq.length) : str
  }
  const lastIndex = str.lastIndexOf(seq)
  return lastIndex != -1 ? str.slice(0, lastIndex) : str
}

const upperCaseRegex = /(?=[A-Z])/
export function insertSpaceBeforeUpperCase(str) {
  return str.split(upperCaseRegex).join(' ')
}
