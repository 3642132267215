import axios from 'axios'
import moment from 'moment'


export const AUTHENTICATED = 'AUTHENTICATED_USER'
export const UNAUTHENTICATED = 'UNAUTHENTICATED_USER'
export const EMAIL_ERROR = 'EMAIL_ERROR'
export const PC_ERROR = 'PASSCODE_ERROR'
export const CLEAN_UP_ERROR = 'CLEAN_UP_ERROR'

export const AUTH_LOADING = 'AUTH_LOADING'

// eslint-disable-next-line max-len
const KEY_WARDEN_BASE = `${process.env.REACT_APP_KEY_WARDEN_HOST}/${process.env.REACT_APP_KEY_WARDEN_STAGE}`


export function authLoading(bool, message) {
  return {
    type: AUTH_LOADING,
    isLoading: bool,
    message,
  }
}

export function sendEmail(email, url, cb) {
  return async (dispatch) => {
    try {
      dispatch(authLoading(true, 'Sending Email'))
      await axios({
        url: `${KEY_WARDEN_BASE}/login`,
        method: 'get',
        params: {
          user: email,
          redirect: url,
          zone: moment.tz.guess(),
        },
      })
      cb()
    } catch (error) {
      dispatch({
        type: EMAIL_ERROR,
        header: `${email} is not authorized to login`,
        content: 'Check the email address and try again.',
      })
    } finally {
      dispatch(authLoading(false))
    }
  }
}

export function logIn(email, passcode, cb) {
  return async (dispatch) => {
    try {
      dispatch(authLoading(true, 'Logging In'))
      const res = await axios({
        url: `${KEY_WARDEN_BASE}/verify`,
        method: 'get',
        params: {
          user: email,
          otp: passcode,
        },
      })

      dispatch({ type: AUTHENTICATED })
      const jwt = res.data.token
      const tokenPayload = JSON.parse(window.atob(jwt.split('.')[1]))

      // save email, access and jwt to localStorage
      window.localStorage.setItem('auth_user', tokenPayload.email)
      window.localStorage.setItem('auth_access', JSON.stringify(tokenPayload.api_access || {}))
      window.localStorage.setItem('auth_jwt', jwt)

      dispatch({ type: CLEAN_UP_ERROR })
      cb()
    } catch (error) {
      dispatch({
        type: PC_ERROR,
        header: 'Invalid Passcode',
        content: 'Check your inbox and try again.',
      })
    } finally {
      dispatch(authLoading(false))
    }
  }
}
