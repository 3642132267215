.padButton {
  padding-bottom: 1.5rem !important;
}

.addDomainButton:first {
  margin-top: 0;
}

.addDomainButton:last {
  margin-bottom: 0;
}

.scrollingSegment {
  height: 30rem;
  overflow-y: scroll;
  margin-top: 1rem;
}

.equalWidth {
  width: 0 !important;
}

.domainButton {
  text-align: left !important;
  margin-top: 1rem !important;
  width: calc(100% - .5rem) !important;
}

.domainButton:first-of-type {
  margin-top: 0 !important;
}

.emptyDiv {
  height: 31rem;
}
