import { combineReducers } from 'redux'
import { cloneDeep } from 'lodash'


function isLoading(state = false, action) {
  switch (action.type) {
    case 'CREATIVES_IS_LOADING':
      return action.isLoading
    default:
      return state
  }
}

function creatives(state = [], action) {
  const creatives = cloneDeep(state)
  switch (action.type) {
    case 'LOAD_CREATIVES':
      return action.creatives
    case 'LOAD_ATTACHED_CREATIVES':
      for (const c of creatives) {
        if (action.creatives.find((attached) => (attached.BannerCode === c.BannerCode))) {
          c.Attached = 1
        } else {
          c.Attached = 0
        }
      }

      return creatives
    default:
      return state
  }
}

function beacons(state = [], action) {
  switch (action.type) {
    case 'LOAD_BEACONS':
      return action.beacons
    default:
      return state
  }
}

const childrenReducer = combineReducers({
  creatives,
  beacons,
  isLoading,
})

export default childrenReducer
