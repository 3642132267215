import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { pick, values } from 'lodash'
import moment from 'moment-timezone'

import CampaignSummaryEdit from './summary-edit'
import { DEFAULT_TZ } from '../../../../utils/time'
import { isNotEmpty, maxLength, runValidators, isDate } from '../../../../utils/form-validators'


const propTypes = {
  campaignData: PropTypes.object,
  newCampaign: PropTypes.bool,
}

const defaultProps = {
  newCampaign: false,
  campaignData: {
    metadata: {
      StartDate: moment().tz(DEFAULT_TZ).format('YYYY-MM-DD'),
      EndDate: moment().tz(DEFAULT_TZ).add(1, 'M').format('YYYY-MM-DD'),
    },
  },
}

class CampaignSummaryEditContainer extends Component {
  constructor(props) {
    super(props)

    const editValues = pick(
      props.campaignData.metadata,
      [
        'StartDate',
        'EndDate',
        'OfferCategories',
        'Name',
        // 'TimeZone',
      ],
    )

    this.state = {
      ...editValues,
      errors: {},
    }
  }

  onChange = (field) => (e, { value }) => {
    this.setState({ [field]: value, errors: {} })
  }

  dateHandler = ({ startDate, endDate }) => {
    const newStartDate = startDate.format('YYYY-MM-DD')
    const newEndDate = endDate
      ? endDate.format('YYYY-MM-DD')
      : ''
    this.setState({ StartDate: newStartDate, EndDate: newEndDate, errors: {} })
  }

  createSaveData() {
    const key = this.props.newCampaign ? 'campaign' : 'metadata'
    return {
      [key]: {
        EndDate: this.state.EndDate,
        OfferCategories: this.state.OfferCategories,
        Name: this.state.Name,
        StartDate: this.state.StartDate,
        // TimeZone: this.state.TimeZone,
      },
    }
  }

  checkAllErrors = () => {
    const errors = {}

    const nameError = runValidators(this.state.Name, 'Name', [isNotEmpty, maxLength])
    errors.Name = nameError.error ? nameError.errorMsg : ''

    let durationError = { error: false }
    const startError = runValidators(this.state.StartDate, 'Duration', [isDate])
    if (startError.error) {
      durationError = startError
    } else {
      durationError = runValidators(this.state.EndDate, 'Duration', [isDate])
    }
    errors.Duration = durationError.error ? durationError.errorMsg : ''

    this.setState({ errors })

    let hasError = false
    values(errors).forEach((v) => {
      if (v !== '') {
        hasError = true
      }
    })

    return hasError
  }

  save = () => {
    const hasError = this.checkAllErrors()

    if (hasError) {
      throw new Error('Unable to update Campaign')
    }

    return this.createSaveData()
  }

  render() {
    const Draft = this.props.newCampaign || this.props.campaignData.metadata.Draft
    const {
      errors,
      EndDate,
      OfferCategories,
      Name,
      StartDate,
    } = this.state

    return (
      <CampaignSummaryEdit
        OfferCategories={OfferCategories}
        Name={Name}
        StartDate={StartDate}
        EndDate={EndDate}
        onChange={this.onChange}
        dateHandler={this.dateHandler}
        errors={errors}
        Draft={Draft}
      />
    )
  }
}

CampaignSummaryEditContainer.propTypes = propTypes

CampaignSummaryEditContainer.defaultProps = defaultProps

export default CampaignSummaryEditContainer
